import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastService } from 'app/services/toast.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as fromActions from './actions'
import { StripeProductsService } from 'app/services/dal/stripeproducts.service';



@Injectable()
export class Effects {

  constructor(
    private actions$: Actions,
    private service: StripeProductsService,
    private toastService: ToastService
  ) { }



  getItems$ = createEffect(() => {
    console.log('getItems');

    return this.actions$
      .pipe(
        ofType(fromActions.loadItems),
        mergeMap((action) => this.service.getPrices(action.productId)
          .pipe(
            map(items => fromActions.loadItemsSuccess({ items: items })),
            catchError(error => of(fromActions.loadItemFailure({ error })))
          )
        )
      );
  });



}
