import { Injectable } from '@angular/core';
import { dbConst } from './databaseConstants';
import { FirestoreCrudService } from './crudService';
import { AuthService } from '../auth.service';
import { IOnTapTemplate } from '../../models/on-tap-template';
import { Firestore } from '@angular/fire/firestore';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OnTapTemplateService {

  private crudService: FirestoreCrudService<IOnTapTemplate>;

  constructor(
    private firestore: Firestore,
    private authService: AuthService) {
    this.crudService = new FirestoreCrudService<IOnTapTemplate>(firestore, authService, dbConst.ontap2Templates);
  }

  //private kegs: IOnTapTemplate[] = [];

  getNewId(): string {
    return this.crudService.getNewId();
  }

  async add(item: IOnTapTemplate, id: string | undefined = undefined) {
    const addItem = { ...item };
    return await this.crudService.add(addItem, id);
  }


  async update(item: IOnTapTemplate) {
    return await this.crudService.update(item);
  }


  async delete(id: string): Promise<void> {
    return await this.crudService.delete(id);
  }


  async softUpdate(id: string, item: any, uid: string) {
    return await this.crudService.softUpdate(id, item, uid);
  }


  getItem(id: string) {
    return this.crudService.get(id);
  }

  getItems(barId: string) {
    return this.crudService.getItemsOrderBy(barId, 'name', undefined);
  }

  getUserAndGlobalItems(barId: string) {

    return this.crudService.getBarAndGlobalItemsOrderBy(barId, 'name');

  }

  getItemsOnce(barId: string) {
    return this.crudService.getItemsOnce(barId, 'name', 100);
  }

  getItemsPromise(barId: string) {
    return this.crudService.getItemsOnce(barId, 'name', 100);
  }


}
