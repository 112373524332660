import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, OnInit, SecurityContext, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable } from '@angular/fire/storage';
//import * as firebase from 'firebase';
import {
  ControlValueAccessor,
  UntypedFormControl,
  ReactiveFormsModule,
  UntypedFormGroup, NG_VALUE_ACCESSOR,
  Validators,
  FormsModule,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent implements OnInit, ControlValueAccessor {

  @Input() pathFolder = 'media';
  @Input() isRequired = false;


  @Output('completed') completion: EventEmitter<any> = new EventEmitter<any>();

  percentage = 0;
  form!: UntypedFormGroup;
  onChange: any;
  onTouched: any;
  value = '';
  fileField!: UntypedFormControl;
  downloadURL!: string;
  backgroundImage!: any;
  fileToUploadId = 'file-to-upload-' + uuid();
  loading = false;
  validateFn: any = () => { };

  constructor(private domSanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.fileField = new UntypedFormControl(
      this.value, [],
    );

    if (this.isRequired) {
      this.fileField.setValidators([Validators.required]);
    } else {
      this.fileField.setValidators([]);
    }

    this.form = new UntypedFormGroup({
      fileField: this.fileField,
    });
  }

  uploadFile(file: any) {

    this.completion.emit(file.files[0]);
    this.loading = true;
    this.percentage = 0;

    // get file
    const file1 = file.files[0];

    // // create storage ref
    const path = `${this.pathFolder}/${file.name}`;
    const storage = getStorage();
    const fileRef = ref(storage, path);


    // const storageRef =  firebase.storage().ref(this.pathFolder + '/' + file1.name);

    // // Upload file
    const uploadTask = uploadBytesResumable(fileRef, file);
    // const task = storageRef.put(file1);

    // // Upload progress bar
    uploadTask.on('state_changed', (snapshot) => {
      this.percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    },
      (error) => {
        this.completion.emit(false);
        console.error(error);
        this.loading = false;
      },
      () => {

        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          this.downloadURL = downloadURL;
          this.backgroundImage = (this.downloadURL && this.downloadURL != null && this.downloadURL !== '')
            ? this.domSanitizer.sanitize(SecurityContext.STYLE, 'url(' + this.downloadURL + ')')
            : this.domSanitizer.sanitize(SecurityContext.STYLE, 'none');
          this.onChange(downloadURL);
          this.loading = false;
          this.completion.emit(true);

        },
          (error) => {
            console.error('upload rejected', error);
            this.loading = false;
            this.completion.emit(false);
          });
      }
    );
  }

  writeValue(value: string) {
    if (value === '') {
      value = '';
    }
    this.value = value;
    this.downloadURL = value;
    this.backgroundImage = (this.downloadURL && this.downloadURL != null && this.downloadURL !== '')
      ? this.domSanitizer.sanitize(SecurityContext.STYLE, 'url(' + this.downloadURL + ')')
      : this.domSanitizer.sanitize(SecurityContext.STYLE, 'none');
    this.form.get('fileField')?.setValue(this.value);
  }

  validate(c: UntypedFormControl) {
    return this.isRequired ? Validators.required : null;
  }

  registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

}
