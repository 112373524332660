import { createAction, props } from '@ngrx/store';
import { IBeverage } from 'app/models/beverage';

export const clearAllItems = createAction('[DalHelper] Clear All Beverages');
export const loadItems = createAction('[Module Component] Load Beverages', props<{ barId: string }>());
// export const loadItemsSorted = createAction('[Taps Page] Load Beverages', props<{ barId: string, sortOrder: string }>());
export const loadItemsSuccess = createAction('[Beverage API] Load Beverages Success', props<{ items: IBeverage[] }>());
export const loadItemsFailure = createAction('[Beverage API] Load Beverages Failure', props<{ error: any }>());

export const setItem = createAction('[Beverage Page] Set selected Beverage', props<{ id: string }>());

export const addItem = createAction('[New Beer Page] Add Beverage', props<{ id: string, item: IBeverage }>());
export const addItemSuccess = createAction('[Beverage API] Add Beverage Success', props<{ id: string }>());
export const addItemFailure = createAction('[Beverage API] Add Beverage Failure', props<{ error: any }>());

export const removeKegFromBeverage = createAction('[Beer Detail Page] Remove Keg From Beverage', props<{ beverageId: string, kegId: string }>());
export const removeKegFromBeverageSuccess = createAction('[Beverage API] Remove Keg From Beverage Success');
export const removeKegFromBeverageFailure = createAction('[Beverage API] Remove Keg From Beverage Failure', props<{ error: any }>());

export const softUpdate = createAction('[Beer Detail Page] Soft Update Beverage', props<{ id: string, data: any, uid: string }>());
export const softUpdateSuccess = createAction('[Beverage API] Soft Update Beverage Success', props<{ id: string, data: any }>());
export const softUpdateFailure = createAction('[Beverage API] Soft Update Beverage Failure', props<{ error: any }>());

export const updateBeverage = createAction('[Beer Detail Page] Update Beverage', props<{ beverage: IBeverage }>());
export const updateBeverageSuccess = createAction('[Beverage API] Update Beverage Success');
export const updateBeverageFailure = createAction('[Beverage API] Update Beverage Failure', props<{ error: any }>());

export const deleteItem = createAction('[Beer Detail Page] Delete Beverage', props<{ id: string }>());
export const deleteItemSuccess = createAction('[Beverage API] Delete Beverage Success');
export const deleteItemFailure = createAction('[Beverage API] Delete Beverage Failure', props<{ error: any }>());



//export const updateBeverageKegs = createAction('[Keg Detail Page] Update Keg On Beverage', props<{ beverage: IBeverage, keg }>());

