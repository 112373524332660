import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as fromActions from './actions'
import { IFont } from 'app/models/font';

// export interface BeverageState {
//   beverages: IBeverage[];
//   error: string;
// }

export interface State extends EntityState<IFont> {
  // additional entity state properties
  selectedId: string | null;
  error: string;
}

export function sortByName(a: IFont, b: IFont): number {
  return a.name.localeCompare(b.name);
}
export function selectId(a: IFont): string {
  return a.id ?? '';
}
export const adapter: EntityAdapter<IFont> = createEntityAdapter<IFont>({
  sortComparer: sortByName,
  selectId: selectId
});

export const initialState: State = adapter.getInitialState({
  selectedId: null,
  error: ''
});

export const stateName = 'font';

// Reducers
export const reducer = createReducer<State>(
  initialState,
  on(fromActions.clearAllItems, (state) => {
    return adapter.removeAll(state);
  }),
  on(fromActions.loadItemsSuccess, (state, { items }) => {
    return adapter.setAll(items, state);
  }),
  on(fromActions.loadItemsFailure, (state, action) => {
    return {
      ...state,
      entities: {},
      error: action.error
    };
  }),



);


// get the selectors
const {
  selectAll,
  selectEntities: selectItemEntities,
  selectIds: selectItemIds,
  selectTotal
} = adapter.getSelectors();


// ***** SELECTORS *******
export const getSelectedId = (state: State) => state.selectedId;
export const getState = createFeatureSelector<State>(stateName);
export const selectIds = createSelector(getState, selectItemIds);
export const selectEntities = createSelector(getState, selectItemEntities);
export const selectAllItems = createSelector(getState, selectAll);
//export const getAllEntities = createSelector(getState, selectEntities);

export const getCount = createSelector(getState, selectTotal);
export const selectCurrentId = createSelector(getState, getSelectedId);
export const selectCurrentItem = createSelector(selectEntities, selectCurrentId, (entities, id) => {
  if (id) return entities[id];
  return null;
});
export const selectEntity = (id: string) => createSelector(selectEntities, entities => entities[id]);
