import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as UnitsActions from './actions'
import { IUnits } from '../../models/units';

export interface State {
  units?: IUnits;
  error: string;
}


const initialKegState: State = {
  units: undefined,
  // {
  //       barId: '',
  //       type: 'metric',
  //       bottles: 'ml',
  //       cans: 'ml',
  //       kegs: 'l',
  //       pour: 'dl',
  //       servings: 'ml',
  //       plaato: 'l'
  //   },
  error: ''
};


export const stateName = 'units';
const getUnitsFeatureState = createFeatureSelector<State>(stateName);

//Selectors
export const getUnits = createSelector(
  getUnitsFeatureState,
  state => state.units
);


export const reducer = createReducer<State>(
  initialKegState,
  on(UnitsActions.loadUnitsSuccess, (state, action): State => {
    return {
      ...state,
      units: action.units,
      error: ''
    };
  }),
  on(UnitsActions.loadUnitsFailure, (state, action): State => {
    return {
      ...state,
      units: undefined,
      error: action.error
    };
  }),
);
