// import { Predicate } from '@angular/core';
// import { Update, EntityMapOne, EntityMap } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { IPlaato } from 'app/models/plaato';


export const clearAllItems = createAction('[DalHelper] Clear All Plaatos');
export const loadItems = createAction('[Settings - Plaato Page] Load Plaatos', props<{ barId: string }>());
export const loadItemsFailure = createAction('[Plaato API] Load Plaatos Failure', props<{ error: any }>());
export const loadPlaatosSuccess = createAction('[Plaato/API] Load Plaatos Success', props<{ items: IPlaato[] }>());


//export const setItem = createAction('[Plaato Api] Set Selected Item inside API', props<{ id: string }>());



export const updateItem = createAction('[Plaato Detail Page] Update Plaato', props<{ item: IPlaato }>());
export const updateItemSuccess = createAction('[Plaato API] Update Plaatos Success', props<{ item: IPlaato }>());
export const updateItemFailure = createAction('[Plaato API] Update Plaatos Failure', props<{ error: any }>());

export const addItem = createAction('[Plaato Detail Page] Add Plaato', props<{ item: IPlaato }>());
export const addItemSuccess = createAction('[Plaato API] Add Plaatos Success', props<{ item: IPlaato }>());
export const addItemFailure = createAction('[Plaato API] Add Plaatos Failure', props<{ error: any }>());

export const deleteItem = createAction('[Plaato Detail Page] Delete Plaato', props<{ id: string }>());
export const deleteItemSuccess = createAction('[Plaato API] Delete Plaatos Success', props<{ id: string }>());
export const deleteItemFailure = createAction('[Plaato API] Delete Plaatos Failure', props<{ error: any }>());


// export const setItem = createAction('[Plaato Detail Page] Set Selected Item',props<{ id: string }>());


// export const softUpdate = createAction('[Plaato API] Soft Update Plaato', props<{ id: string, data: any }>());
// export const softUpdateSuccess = createAction('[Plaato API] Soft Update Plaato Success', props<{id: string, data: any}>());
// export const softUpdateFailure = createAction('[Plaato API] Soft Update Plaato Failure', props<{ error: any }>());



// export const setPlaatosSuccess = createAction('[Plaato/API] Set Plaatos Success', props<{ items: IPlaato[] }>());
// export const addPlaatoSuccess = createAction('[Plaato/API] Add Plaato Success', props<{ item: IPlaato }>());
// export const setPlaatoSuccess = createAction('[Plaato/API] Set Plaato Success', props<{ item: IPlaato }>());
// export const upsertPlaatoSuccess = createAction('[Plaato/API] Upsert Plaato Success', props<{ item: IPlaato }>());
// export const addPlaatosSuccess = createAction('[Plaato/API] Add Plaatos Success', props<{ items: IPlaato[] }>());
// export const upsertPlaatosSuccess = createAction('[Plaato/API] Upsert Plaatos Success', props<{ items: IPlaato[] }>());
// export const updatePlaatoSuccess = createAction('[Plaato/API] Update Plaato Success', props<{ update: Update<IPlaato> }>());
// export const updatePlaatosSuccess = createAction('[Plaato/API] Update Plaatos Success', props<{ updates: Update<IPlaato>[] }>());
// export const mapPlaatoSuccess = createAction('[Plaato/API] Map Plaato Success', props<{ entityMap: EntityMapOne<IPlaato> }>());
// export const mapPlaatosSuccess = createAction('[Plaato/API] Map Plaatos Success', props<{ entityMap: EntityMap<IPlaato> }>());
// export const deletePlaatoSuccess = createAction('[Plaato/API] Delete Plaato Success', props<{ id: string }>());
// export const deletePlaatosSuccess = createAction('[Plaato/API] Delete Plaatos Success', props<{ ids: string[] }>());
// export const deletePlaatosByPredicateSuccess = createAction('[Plaato/API] Delete Plaatos By Predicate Success', props<{ predicate: Predicate<IPlaato> }>());
// export const clearPlaatosSuccess = createAction('[Plaato/API] Clear Plaatos Success');

