import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastService } from 'app/services/toast.service';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as fromActions from './actions'
import { OnTapTemplateService } from 'app/services/dal/onTapTemplate.service';



@Injectable()
export class Effects {

  constructor(
    private actions$: Actions,
    private service: OnTapTemplateService,
    private toastService: ToastService
  ) { }

  getItems$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromActions.loadItems),
        mergeMap((action) => this.service.getUserAndGlobalItems(action.barId)
          .pipe(
            map((item) => fromActions.loadItemsSuccess({ a: item.a, b: item.b })),
            catchError(error => of(fromActions.loadItemsFailure({ error })))
          )
        )
      );
  });



  create$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromActions.addItem),
        mergeMap((action) => from(this.service.add(action.item, action.id))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
            }),
            catchError(error => of(fromActions.addItemFailure({ error })))
          )
        )
      );
  },
    { dispatch: false }
  );


  deleteItem$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromActions.deleteItem),
        mergeMap((action) => of(this.service.delete(action.id))
          .pipe(
            map(() => {
              this.toastService.deleteItemSuccess();
              return fromActions.deleteItemSuccess({ id: action.id });
            }),
            catchError(error => of(fromActions.addItemFailure({ error })))
          )
        )
      );
  });


  softUpdate$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromActions.softUpdate),
        mergeMap((action) => from(this.service.softUpdate(action.id, action.data, action.uid))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
            }),
            catchError(error => of(fromActions.addItemFailure({ error })))
          )
        )
      );
  },
    { dispatch: false }
  );


}
