export const environment = {
  version: "5.0.0",
  notificationsCount: 15,
  production: true,
  reCaptchaKey: '6LfbevgoAAAAAMKzhcfrefRGNtqreoa0vrPTqtWi',
  purchaseLink: 'https://promsys.thrivecart.com/bar-helper/',
  firebase: {
    apiKey: 'AIzaSyC_uoFIkpSIXTXSyE8E0eIBMvWa9hR5Ar4',
    authDomain: 'barhelper-app.firebaseapp.com',
    databaseURL: 'https://barhelper-app.firebaseio.com',
    projectId: 'barhelper-app',
    storageBucket: 'barhelper-app.appspot.com',
    messagingSenderId: '1031783853634',
    appId: '1:1031783853634:web:6867c3ef63daec368147db',
    measurementId: 'G-MSM5DWR9GC',
    vapidKey: 'BPPPbP_ReGiTspA4C8e7kFxr5u3kFZ67ydvnU0_xkDt1fvLnk_XPcUPugxORhZcn3vUnIos3hJrvd4IW5C0xuI4'
  },
  untappd: {
    clientId: 'B9D3E3D7DA1464F18DB9DCA91979E9D0DA9AE5AC',
    clientSecret: '3951CE70C02687D9166858F16075D3849CAA3081',
    redirectUrl: 'https://barhelper.app/settings/untappd',

    authorizeUrl: 'https://untappd.com/oauth/authorize/?client_id=B9D3E3D7DA1464F18DB9DCA91979E9D0DA9AE5AC&client_secret=3951CE70C02687D9166858F16075D3849CAA3081&response_type=code&redirect_url=https://barhelper.app/settings/untappd&code=',
    authenticateUrl: 'https://untappd.com/oauth/authenticate/?client_id=B9D3E3D7DA1464F18DB9DCA91979E9D0DA9AE5AC&response_type=code&redirect_url=https://barhelper.app/settings/untappd'
  }, sentry: {
    dsn: 'https://54d4ea8c14d84547aa97306e463b90c7@o413870.ingest.sentry.io/5302389',
    disableLogging: false,
    projectName: 'bar-helper'
  },
  onTapTvUrl: 'https://tv.barhelper.app',
  onTap2TvUrl: 'https://ontap.barhelper.app/tv'

};
