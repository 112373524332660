import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { IPlaatoStatus } from 'app/models/plaato-status';
import * as PlaatoActions from './actions'

export interface State extends EntityState<IPlaatoStatus> {
  // additional entity state properties
  selectedId: string | null;
  error: string;
}

export function selectPlaatoId(a: IPlaatoStatus): string {
  //In this case this would be optional since primary key is id
  return a.id ?? '';
}
export const adapter: EntityAdapter<IPlaatoStatus> = createEntityAdapter<IPlaatoStatus>({
  selectId: selectPlaatoId
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedId: null,
  error: ''
});

export const stateName = 'plaato-status';

export const reducer = createReducer<State>(
  initialState,
  // on(PlaatoActions.addItem, (state, { item }) => {
  //   return adapter.addOne(item, state)
  // }),
  // on(PlaatoActions.setPlaatoSuccess, (state, { item }) => {
  //   return adapter.setOne(item, state)
  // }),
  on(PlaatoActions.upsertItem, (state, { item }) => {
    return adapter.upsertOne(item, state);
  }),
  // on(PlaatoActions.addPlaatosSuccess, (state, { items }) => {
  //   return adapter.addMany(items, state);
  // }),
  // on(PlaatoActions.upsertPlaatosSuccess, (state, { items }) => {
  //   return adapter.upsertMany(items, state);
  // }),
  // on(PlaatoActions.updatePlaatoSuccess, (state, { update }) => {
  //   return adapter.updateOne(update, state);
  // }),
  // on(PlaatoActions.updatePlaatosSuccess, (state, { updates }) => {
  //   return adapter.updateMany(updates, state);
  // }),
  // on(PlaatoActions.mapPlaatoSuccess, (state, { entityMap }) => {
  //   return adapter.mapOne(entityMap, state);
  // }),
  // on(PlaatoActions.mapPlaatosSuccess, (state, { entityMap }) => {
  //   return adapter.map(entityMap, state);
  // }),
  on(PlaatoActions.deleteItem, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  // on(PlaatoActions.deletePlaatosSuccess, (state, { ids }) => {
  //   return adapter.removeMany(ids, state);
  // }),
  // on(PlaatoActions.deletePlaatosByPredicateSuccess, (state, { predicate }) => {
  //   return adapter.removeMany(predicate, state);
  // }),
  // on(PlaatoActions.setPlaatosSuccess, (state, { items }) => {
  //   return adapter.setMany(items, state);
  // }),
  // on(PlaatoActions.clearPlaatosSuccess, state => {
  //   return adapter.removeAll({ ...state, selectedUserId: null });
  // })
);

// get the selectors
const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal
} = adapter.getSelectors();


// ***** SELECTORS *******
export const getSelectedId = (state: State) => state.selectedId;
export const getState = createFeatureSelector<State>(stateName);
export const getAllItems = createSelector(getState, selectAll);
export const getEntities = createSelector(getState, selectEntities);
export const getCurrentId = createSelector(getState, getSelectedId);
export const getCurrentItem = createSelector(getEntities, getCurrentId, (entities, id) => {
  if (id) return entities[id];
  return null;
});
export const getEntity = (id: string) => createSelector(getEntities, entities => entities[id]);

