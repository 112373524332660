import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { IBarSettings } from 'app/models/barSettings';

export const clearAllItems = createAction('[DalHelper] Clear All BarSetting');
export const loadItem = createAction('[Module Component] Load BarSetting', props<{ barId: string }>());
export const loadItemSuccess = createAction('[BarSettings API] Load BarSetting Success', props<{ item: IBarSettings }>());
export const loadItemFailure = createAction('[BarSettings API] Load BarSetting Failure', props<{ error: any }>());

export const loadItems = createAction('[Module Component] Load BarSettings', props<{ barId: string }>());
export const loadItemsSuccess = createAction('[BarSettings API] Load BarSettings Success', props<{ items: IBarSettings[] }>());
export const loadItemsFailure = createAction('[BarSettings API] Load BarSettings Failure', props<{ error: any }>());

export const setItem = createAction('[Module Component] Set selected BarSettings', props<{ id: string }>());

export const updateItem = createAction('[Home-All Page] Update BarSettings', props<{ update: Update<IBarSettings> }>());
export const updateItemSuccess = createAction('[BarSettings API] Update BarSettings Success', props<{ update: Update<IBarSettings> }>());
export const updateItemFailure = createAction('[BarSettings API] Update BarSettings Failure', props<{ error: any }>());

