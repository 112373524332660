import { User } from '@angular/fire/auth';
import { createAction, props } from '@ngrx/store';
import { IUser } from 'app/models/user';


export const CLEAR_STATE = '[App] Clear State';
export const clearStore = createAction(CLEAR_STATE);


export const loadUser = createAction('[User] Load UserAuth');
export const loadUserSuccess = createAction('[User] Load User Success', props<{ user: User }>());
export const loadUserFailure = createAction('[User] Load User Failure', props<{ error: any }>());

export const loadUserFromFirestore = createAction('[User] Load User from firestore');
export const loadUserFromFirestoreSuccess = createAction('[User] Load User from firestore Success', props<{ user: User }>());
export const loadUserFromFirestoreFailure = createAction('[User] Load User from firestore Failure', props<{ error: any }>());

// export const loadUserFromFier = createAction('[User] Load UserAuth');
// export const loadUserSuccess = createAction('[User] Load User Success',props<{ user: User }>());
// export const loadUserFailure = createAction('[User] Load User Failure',props<{ error: any }>());



export const loadSubscriptions = createAction('[User Effect] Load Subscriptions', props<{ barId: string }>());
export const loadSubscriptionsSuccess = createAction('[User Effect] Load Subscriptions Success', props<{ barId: string, subs: any[] }>());

export const loadBarId = createAction('[User] Load BarId');
export const loadBarIdByFunction = createAction('[User] Load BarId from function');

export const loadBarIdFromUserFirestore = createAction(
  '[User] Load BarId From User in Firestore',
  props<{ uid: string }>()
);

export const loadBarIdSuccess = createAction(
  '[User] Load BarId Success',
  props<{ barId: string }>()
);

export const loadBarIdFailure = createAction(
  '[User] Load BarId Failure',
  props<{ error: any }>()
);



// export const loadHasPremium = createAction(
//   '[User] Load Has Premium',
//   props<{ barId: string }>()
// );

// export const loadHasPremiumFromApp = createAction(
//   '[App Controller] Load Has Premium',
//   props<{ barId: string }>()
// );

// export const loadHasPremiumSuccess = createAction(
//   '[User] Load Has Premium Success',
//   props<{ hasPremium: boolean }>()
// );

// export const loadHasPremiumFromAppSuccess = createAction(
//   '[User Service] Load Has Premium from App Success',
//   props<{ hasPremium: boolean }>()
// );

// export const loadHasPremiumFailure = createAction(
//   '[User] Load Has Premium Failure',
//   props<{ error: any }>()
// );
