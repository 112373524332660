import { Injectable } from '@angular/core';
import { dbConst } from './databaseConstants';
import { FirestoreCrudService } from './crudService';
import { AuthService } from '../auth.service';
import * as firebase from 'firebase/app';
import { IBar } from '../../models/bar';
import { IBeerStyle } from '../../models/beerstyle';
import { importBeerStyles } from '../../imports/beerstyles';
import { map } from 'rxjs/operators';
import { collection, collectionData, Firestore, getDocs, limit, orderBy, query, where } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BeerstyleService {

  private crudService: FirestoreCrudService<IBeerStyle>;

  constructor(
    private firestore: Firestore,
    private authService: AuthService) {
    this.crudService = new FirestoreCrudService<IBar>(firestore, authService, dbConst.beerstyles);
  }


  getNewId() {
    return this.crudService.getNewId();
  }

  getItem(id: string) {
    return this.crudService.get(id);
  }

  getItems(barId: string) {
    const listRef = collection(this.firestore, dbConst.beerstyles);
    const q = query(listRef, where('barId', 'in', ['', barId]), orderBy('name'));
    return collectionData(q, { idField: 'id' }) as Observable<IBeerStyle[]>;
  }

  async findStyle(barId: string, name: string) {

    const listRef = collection(this.firestore, dbConst.beerstyles);
    const q = query(listRef, where('barId', 'in', ['', barId]), where('name', '==', name));
    const querySnap = await getDocs(q);

    const items: IBeerStyle[] = [];

    querySnap.forEach((doc) => {

      const item = doc.data();
      item['id'] = doc.id;
      items.push(item as IBeerStyle);
    });

    return items;
  }

  update(item: IBeerStyle) {
    return this.crudService.update(item);
  }

  add(item: IBeerStyle, id: string | undefined = undefined) {
    return this.crudService.add({ ...item }, id);
  }
  delete(id: string) {
    return this.crudService.delete(id);
  }

  import() {

    // const styles = [
    //   {
    //     name: 'Default'
    //   },
    //   {
    //     name: 'Pale lager'
    //   },
    //   {
    //     name: 'Witbier'
    //   },
    //   {
    //     name: 'Berliner Weisse'
    //   },
    //   {
    //     name: 'Maibock'
    //   },
    //   {
    //     name: 'Blond Ale'
    //   },
    //   {
    //     name: 'Weissbier'
    //   },
    //   {
    //     name: 'American Pale Ale'
    //   },
    //   {
    //     name: 'India Pale Ale'
    //   },
    //   {
    //     name: 'Weissbier'
    //   },
    //   {
    //     name: 'Saison'
    //   },
    //   {
    //     name: 'English Bitter'
    //   },
    //   {
    //     name: 'ESB'
    //   },
    //   {
    //     name: 'Biere de Garde'
    //   },
    //   {
    //     name: 'Double IPA'
    //   },
    //   {
    //     name: 'Dark lager'
    //   },
    //   {
    //     name: 'Vienna lager'
    //   },
    //   {
    //     name: 'Marzen'
    //   },
    //   {
    //     name: 'Amber Ale'
    //   },
    //   {
    //     name: 'Brown Ale'
    //   },
    //   {
    //     name: 'Bock'
    //   },
    //   {
    //     name: 'Dunkel'
    //   },
    //   {
    //     name: 'Dunkelweizen'
    //   },
    //   {
    //     name: 'Irish Dry Stout'
    //   },
    //   {
    //     name: 'Doppelbock'
    //   },
    //   {
    //     name: 'Porter'
    //   },
    //   {
    //     name: 'Stout'
    //   },
    //   {
    //     name: 'Foreign Stout'
    //   },
    //   {
    //     name: 'Baltic Porter'
    //   },
    //   {
    //     name: 'Imperial Stout'
    //   },
    //   {
    //     name: 'Other'
    //   }

    // ];

    // styles.forEach(element => {
    //   const item: IBeerStyle = {
    //     barId: '',
    //     name: element.name
    //   };
    //   this.crudService.add(item);
    // });
  }

}
