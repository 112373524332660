import { Injectable } from '@angular/core';
import { dbConst } from './databaseConstants';
import { FirestoreCrudService } from './crudService';
import { AuthService } from '../auth.service';
import { ILocation } from '../../models/location';
import { IBeverage } from '../../models/beverage';
import { collection, collectionData, Firestore, query, where } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private crudService: FirestoreCrudService<ILocation>;
  constructor(
    private firestore: Firestore,
    private authService: AuthService) {
    this.crudService = new FirestoreCrudService<ILocation>(firestore, authService, dbConst.locations);
  }

  public triggerActive = false;

  getNewId(): string {
    return this.crudService.getNewId();
  }

  async add(item: ILocation, id: string | undefined = undefined) {
    return await this.crudService.add(item, id);
  }

  async update(item: ILocation) {
    return await this.crudService.update(item);
  }

  async softUpdate(id: string, item: any, uid: string) {
    return await this.crudService.softUpdate(id, item, uid);
  }

  async delete(id: string) {
    await this.crudService.delete(id);
  }

  getItem(id: string) {
    return this.crudService.get(id)
  }

  getItems(barId: string) {
    return this.crudService.getItemsOrder(barId);
  }

  getBeverages(locationId: string) {

    const listRef = collection(this.firestore, dbConst.beverages);
    const q = query(listRef, where('locationId', '==', locationId));
    return collectionData(q, { idField: 'id' }) as Observable<IBeverage[]>;
  }

}
