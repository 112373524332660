import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { KegService } from "app/services/dal/keg.service";
import { PlaatoService } from "app/services/dal/plaato.service";
import { of } from "rxjs";
import { catchError, map, mergeMap, tap, take } from "rxjs/operators";
import * as PlaatoActions from "./actions";
import { ToastService } from "app/services/toast.service";
import * as fromPlaato from "app/state/plaato";
import { Store } from "@ngrx/store";

@Injectable()
export class PlaatoEffects {
  constructor(
    private store: Store<fromPlaato.State>,
    private actions$: Actions,
    private plaatoService: PlaatoService,
    private toastService: ToastService
  ) { }

  getPlaatos$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PlaatoActions.loadItems),
      mergeMap((action) =>
        this.plaatoService.getItems(action.barId).pipe(
          map((items) => PlaatoActions.loadPlaatosSuccess({ items: items })),
          catchError((error) => of(PlaatoActions.loadItemsFailure({ error })))
        )
      )
    );
  });

  updatePlaato$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PlaatoActions.updateItem),
      mergeMap((action) =>
        of(this.plaatoService.update(action.item)).pipe(
          map(() => {
            this.toastService.saveSuccess();
            return PlaatoActions.updateItemSuccess({ item: action.item });
          }),
          catchError((error) => of(PlaatoActions.updateItemFailure({ error })))
        )
      )
    );
  });

  addPlaato$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PlaatoActions.addItem),
      mergeMap((action) =>
        of(this.plaatoService.add(action.item)).pipe(
          map(() => {
            this.toastService.saveSuccess();
            return PlaatoActions.addItemSuccess({ item: action.item });
          }),
          catchError((error) => {
            this.toastService.error(error);
            return of(PlaatoActions.addItemFailure({ error }))
          })
        )
      )
    );
  });

  deletePlaato$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PlaatoActions.deleteItem),
      mergeMap((action) =>
        of(this.plaatoService.delete(action.id)).pipe(
          map(() => {
            this.toastService.deleteItemSuccess();
            return PlaatoActions.deleteItemSuccess({ id: action.id });
          }),
          catchError((error) => of(PlaatoActions.addItemFailure({ error })))
        )
      )
    );
  });
}
