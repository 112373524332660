import { Injectable, NgZone } from '@angular/core';
import { IUser } from '../models/user';

import { addDoc, collection, Firestore } from '@angular/fire/firestore';
import {
  Auth, getAuth, User, onAuthStateChanged, signInWithEmailAndPassword,
  createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, updateProfile,
  UserCredential, signOut, authState, IdTokenResult,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider
} from '@angular/fire/auth';
import { getFunctions, httpsCallable } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from './dal/user.service';
import { SubscriptionHandler } from './subscriptionHandler.service';
import { dbConst, regionConst } from './dal/databaseConstants';
import { getApp } from '@angular/fire/app';
import { Store } from '@ngrx/store';
import * as fromUser from 'app/state/user';

@Injectable({
  providedIn: 'root',
})

export class AuthService {
  constructor(
    public firestore: Firestore, // Inject Firestore service
    public afAuth: Auth, // Inject Firebase auth service
    private subscriptionHandler: SubscriptionHandler,
    private userService: UserService,
    public router: Router,
    public ngZone: NgZone,
  ) {



    // onAuthStateChanged(auth, (user) => {
    //   if (user) {
    //     // User is signed in, see docs for a list of available properties
    //     // https://firebase.google.com/docs/reference/js/firebase.User
    //     const uid = user.uid;
    //     // ...
    //   } else {
    //     // User is signed out
    //     // ...
    //   }
    // });


    // this.afAuth.authState.subscribe( authState => {
    //   this.authState = authState;

    // });

    this.getAuthState();
    this.onAuthStateChanged();
  }

  refreshTime!: number;
  authState: any = null;

  user$!: Observable<User | undefined | null>;
  user!: User;
  barId!: string;

  public userId!: string;

  // get;
  // boolean;

  //#region NgRx
  getUserFromAuthState() {
    const auth = getAuth();
    return authState(auth);
  }


  getBarId(): Observable<string> {
    const auth = getAuth();

    const barIdSubject = new BehaviorSubject<string>('');
    const barId$ = barIdSubject.asObservable();

    onAuthStateChanged(auth, (user) =>
      user?.getIdTokenResult(true).then((result: IdTokenResult) => {
        const barId = result.claims['barOwner'] != null && (result.claims['barOwner'] as string[]).length > 0
          ? (result.claims['barOwner'] as string[])[0]
          : '';

        barIdSubject.next(barId)

      })
    );

    return barId$;

  }



  async getBarIdFromFunction() {
    const functions = getFunctions(getApp(), regionConst.europeWest1);
    const callable = httpsCallable(functions, 'getBarId');
    const retValue = await callable();
    return retValue;
  }


  async getUserHasPremium(barId: string): Promise<boolean> {
    const claims = await this.getUserTokenClaim();
    const subs = claims?.claims['subs'] as any[];
    const bar = subs.find(x => x.barId == barId);
    if (!(bar && bar.expDate)) {
      return false;
    }
    const expDate = new Date(bar.expDate);
    return expDate >= new Date();
  }

  async getUserSubscriptions(): Promise<any[]> {
    const claims = await this.getUserTokenClaim();
    const subs = claims?.claims['subs'] as any[];
    return subs;
  }

  //#endregion NgRx






  getUser(skipLocalStorage = false): User | undefined {
    if (localStorage.getItem('user') || skipLocalStorage) {
      const user = JSON.parse(localStorage.getItem('user') ?? '') as User;
      return user;
    }
    const auth = getAuth();
    if (auth.currentUser) {
      return auth.currentUser;
    }
    return undefined;


  }



  reloadUser(): Promise<void> | undefined {
    const auth = getAuth();

    return auth?.currentUser?.reload();
  }

  async loginWithGoogle() {
    const auth = getAuth();

    return await signInWithPopup(auth, new GoogleAuthProvider());
  }

  async loginWithFacebook() {
    const auth = getAuth();

    return await signInWithPopup(auth, new FacebookAuthProvider())
  }


  async login(
    email: string,
    password: string
  ): Promise<UserCredential> {

    const auth = getAuth();

    return await signInWithEmailAndPassword(auth, email, password);
  }

  async signup(
    displayName: string,
    email: string,
    password: string
  ): Promise<UserCredential> {
    try {

      const auth = getAuth();
      const cred = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(cred.user);
      await updateProfile(cred.user, { displayName: displayName });


      const entity: IUser = {
        uid: cred.user.uid,
        displayName: displayName,
        email: cred.user.email ?? undefined,
        emailVerified: cred.user.emailVerified,
        photoURL: ''
      };


      const collRef = collection(this.firestore, dbConst.users);
      await addDoc(collRef, entity);

      return cred;
    } catch (error) {
      throw error;
    }
  }

  resetPassword(email: string): Promise<void> {
    const auth = getAuth();

    return sendPasswordResetEmail(auth, email);
  }

  async logout(): Promise<void> {
    const auth = getAuth();

    this.subscriptionHandler.clearSubscriptions();
    localStorage.clear();
    return signOut(auth).then(async () => {
      //localStorage.clear();
      // await window.indexedDB.deleteDatabase("firebaseLocalStorageDb");
      // await window.indexedDB.deleteDatabase("firestore/[DEFAULT]/" + environment.firebase.projectId + "/main");
    });
  }


  // // Sign in with Google
  // googleAuthenticate(): Promise<firebase.auth.UserCredential> {
  //   return this.afAuth.signInWithPopup(new auth.GoogleAuthProvider());
  // }

  // // Sign in with Google
  // facebookAuthenticate(): Promise<firebase.auth.UserCredential> {
  //   return this.afAuth.signInWithPopup(new auth.FacebookAuthProvider());
  // }

  onAuthStateChanged() {
    const callback = null;
    const metadataRef = null;
    const auth = getAuth();



    onAuthStateChanged(auth, (user) => {

      // this.store.dispatch(UserActions.loadUser({user: user}));

      // On user login add new listener.
      if (user) {
        // Check if refresh is required.
        this.userService.getMetadataUser(user.uid).subscribe((data) => {

          if (!data) {
            this.userService.setMetaDataUser(user.uid);
            return;
          }


          const refreshTime = localStorage.getItem('refreshTime');
          const barId = localStorage.getItem('barId');

          // if(refreshTime == data.refreshTime && barId)
          // {

          //   this.subscriptionService.userHasPremium(this.barId, false);
          //   return;
          // }
          // else {
          //   localStorage.setItem('refreshTime', data.refreshTime);
          //   localStorage.setItem('barId', this.barId);
          //   user.getIdToken(true).catch((error) => {
          //     console.error('getTokenError', error);
          //   });
          //   this.subscriptionService.userHasPremium(this.barId, true);

          // }
        });
      }
    });
  }


  getUserTokenClaim(): Promise<IdTokenResult> | undefined {
    const auth = getAuth();
    return auth.currentUser?.getIdTokenResult();
  }


  getUserBarId(): Promise<string> {

    const promise = new Promise<string>(async (resolve, reject) => {
      if (this.barId) {
        resolve(this.barId);
      }
      else {
        const auth = getAuth();
        if (!auth.currentUser) {
          this.getAuthState();
          while (!this.barId) {
            await new Promise(f => setTimeout(f, 500));
            this.barId = localStorage.getItem('barId') ?? '';
          }
          if (!this.barId) {
            reject('Unable to get barId')
          } else {
            resolve(this.barId);
          }
        } else {
          this.barId = await this.getBarIdFromTokenResult() ?? '';
          if (!this.barId) {
            reject('Unable to get barId')
          } else {
            resolve(this.barId);
          }



          // auth.currentUser.getIdTokenResult()
          // .then((result) => {

          //   this.barId =
          //     result.claims.barOwner != null && result.claims.barOwner.length > 0
          //       ? result.claims.barOwner[0]
          //       : null;
          //   resolve(this.barId);
          // })
          // .catch(error => {
          //   this.router.navigateByUrl('/login');
          //   reject(error);
          // });
        }

      }


    });
    return promise;
  }


  async getBarIdFromTokenResult() {
    const auth = getAuth();
    if (auth) {
      const barId = await auth.currentUser?.getIdTokenResult()
        .then((result) => {
          return result.claims['barOwner'] != null && (result.claims['barOwner'] as string[]).length > 0
            ? (result.claims['barOwner'] as string[])[0]
            : null;
        })
        .catch(error => {
          this.router.navigateByUrl('/login');
          return null;
        });
      return barId;
    }
    return null
  }

  getAuthState() {
    const auth = getAuth() as any;

    this.user$ = authState(auth);
    const ret$ = this.user$.subscribe((user) => {
      if (user) {
        this.user = user;
        localStorage.setItem('user', JSON.stringify(this.user));
      } else {
        localStorage.setItem('user', '');
        this.logout().then(() => {
          this.router.navigate(['login']);
        });
      }
    });
    return this.user$;
  }



  isLoggedIn() {
    const user = JSON.parse(localStorage?.getItem('user') ?? '');
    return user !== null;
  }

  // Sign in with email/password
  SignIn(email: string, password: string) {

    const auth = getAuth();

    return signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        return userCredential;
        // Signed in
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        window.alert(error.message);
      });
  }

  // Sign up with email/password
  SignUp(email: string, password: string) {
    const auth = getAuth();

    return createUserWithEmailAndPassword(auth, email, password)
      .then(async (result) => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        await this.sendVerificationMail();
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }

  // Send email verfificaiton when new user sign up
  sendVerificationMail() {
    const auth = getAuth();
    if (!!auth.currentUser) {
      sendEmailVerification(auth.currentUser)
        .then(() => {
          this.router.navigate(['verify-email']);
        })
    }
  }



  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    const auth = getAuth();
    sendPasswordResetEmail(auth, passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  // // Sign in with Google
  // GoogleAuth() {
  //   return this.AuthLogin(new auth.GoogleAuthProvider());
  // }

  // // Sign in with Google
  // FacebookAuth() {
  //   this.afAuth.auth.setPersistence('local');

  //   return this.AuthLogin(new auth.FacebookAuthProvider());
  // }

  // Auth logic to run auth providers
  // AuthLogin(provider) {
  //   return this.afAuth.auth
  //     .signInWithPopup(provider)
  //     .then((result) => {
  //       this.ngZone.run(() => {
  //         this.router.navigate(['home']);
  //       });
  //     })
  //     .catch((error) => {
  //       window.alert(error);
  //     });
  // }

  impersonate(barId: string): Promise<any> {
    const functions = getFunctions(getApp(), regionConst.europeWest1);
    const callable = httpsCallable(functions, 'impersonateBar');
    return callable({
      barId
    });
  }

}
function typeOf(expDate: any): any {
  throw new Error('Function not implemented.');
}

