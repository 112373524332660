import { User } from '@angular/fire/auth/firebase';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as UserActions from './actions'

export interface State {
  uid: string;
  barId: string;
  currentUser?: User;
  subs: any[];
  // hasPremium?: boolean;
  error: string;
}


const initialUserState: State = {
  uid: '',
  barId: '',
  currentUser: undefined,
  subs: [],
  // hasPremium: null,
  error: ''
};


export const stateName = 'user';

const getUserFeatureState = createFeatureSelector<State>(stateName);

//Selectors
export const getUser = createSelector(
  getUserFeatureState,
  state => state.currentUser
);

export const getBarId = createSelector(
  getUserFeatureState,
  state => state.barId
);
export const getUserId = createSelector(
  getUserFeatureState,
  state => state.uid
);

// export const getHasPremium = createSelector(
//   getUserFeatureState,
//   state => state.hasPremium
// );


export const reducer = createReducer<State>(
  initialUserState,

  on(UserActions.loadUserSuccess, (state, action): State => {
    return {
      ...state,
      uid: action.user?.uid,
      currentUser: action.user,
      error: ''
    };
  }),
  on(UserActions.loadUserFailure, (state, action): State => {
    return {
      ...state,
      uid: '',
      currentUser: undefined,
      error: action.error
    };
  }),
  on(UserActions.loadBarIdSuccess, (state, action): State => {
    return {
      ...state,
      barId: action.barId,
      error: ''
    };
  }),
  on(UserActions.loadSubscriptionsSuccess, (state, action): State => {
    return {
      ...state,
      subs: action.subs,
      error: ''
    };
  }),
  on(UserActions.loadBarIdFailure, (state, action): State => {
    return {
      ...state,
      barId: '',
      error: action.error
    };
  }),
  // on(UserActions.loadHasPremiumSuccess, (state, action): UserState => {
  //   return {
  //     ...state,
  //     hasPremium: action.hasPremium,
  //     error: ''
  //   };
  // }),
  // on(UserActions.loadHasPremiumFailure, (state, action): UserState => {
  //   return {
  //     ...state,
  //     hasPremium: false,
  //     error: action.error
  //   };
  // }),
);
