import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastService } from 'app/services/toast.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as fromActions from './actions'
import { StripeCustomerService } from 'app/services/dal/stripecustomer.service';



@Injectable()
export class Effects {

  constructor(
    private actions$: Actions,
    private service: StripeCustomerService,
    private toastService: ToastService
  ) { }

  getItem$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromActions.loadItem),
        mergeMap((action) => this.service.getItem(action.id)
          .pipe(
            map(item => fromActions.loadItemSuccess({ item: item })),
            catchError(error => of(fromActions.loadItemFailure({ error })))
          )
        )
      );
  });



}
