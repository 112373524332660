import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as Sentry from '@sentry/browser';


@Injectable()
export class SentryIonicErrorHandler extends ErrorHandler {
  override handleError(error: any) {
    super.handleError(error);
    try {
      if (!environment.sentry.disableLogging) {
        Sentry.captureException(error.originalError || error);
      } else {
        console.info('Sentry Logging Disabled');
      }
    } catch (e) {
      console.error(e);
    }
  }
}
