import { Injectable } from '@angular/core';
import { IKeg } from '../../models/keg';
import { dbConst } from './databaseConstants';
import { BeverageService } from './beverage.service';
import { FirestoreCrudService } from './crudService';
import { AuthService } from '../auth.service';
import { collection, collectionData, Firestore, query, Timestamp, where } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class KegService {

  private crudService: FirestoreCrudService<IKeg>;

  constructor(
    private firestore: Firestore,
    private authService: AuthService) {
    this.crudService = new FirestoreCrudService<IKeg>(firestore, authService, dbConst.kegs);
  }

  private kegs: IKeg[] = [];

  getNewId(): string {
    return this.crudService.getNewId();
  }


  async add(item: IKeg, id: string | undefined = undefined) {
    const addItem = { ...item };
    addItem.tapDate = addItem.tapDate ? new Date(addItem.tapDate) : null;
    addItem.cleanDate = addItem.cleanDate ? new Date(addItem.cleanDate) : null;
    addItem.bestBeforeDate = addItem.bestBeforeDate ? new Date(addItem.bestBeforeDate) : null;

    return await this.crudService.add(addItem, id);
  }

  async update(item: IKeg) {
    const updateItem = { ...item };

    updateItem.tapDate = updateItem.tapDate ? new Date(updateItem.tapDate) : null;
    updateItem.cleanDate = updateItem.cleanDate ? new Date(updateItem.cleanDate) : null;
    updateItem.conditionDate = updateItem.conditionDate ? new Date(updateItem.conditionDate) : null;
    updateItem.bestBeforeDate = updateItem.bestBeforeDate ? new Date(updateItem.bestBeforeDate) : null;
    return await this.crudService.update(updateItem);
  }

  async softUpdate(id: string, item: any, uid: string) {
    return await this.crudService.softUpdate(id, item, uid);
  }

  async removeTapFromKeg(item: IKeg) {
    if (item.id)
      return await this.crudService.softUpdate(item.id, {
        plaatoId: '',
        plaatoName: '',
        plaatoApiKey: '',
        customKegMonId: '',
        customKegMonName: '',
        kegtronId: '',
        kegtronName: '',
        tapId: '',
        tapName: '',
      }
      );
  }


  async delete(id: string): Promise<void> {
    return await this.crudService.delete(id);
  }


  getItem(id: string) {
    return this.crudService.get(id);
  }

  getItemOnce(id: string) {
    return this.crudService.getOnce(id);
  }

  getItems(barId: string) {
    return this.crudService.getItemsOrderBy(barId, 'name', undefined);
  }

  getItemsOnce(barId: string) {
    return this.crudService.getItemsOnce(barId, 'name', 200);
  }

  getItemsPromise(barId: string) {
    return this.crudService.getItemsOnce(barId, 'name', 200);
  }

  getKegs(kegIds: string[]) {
    const listRef = collection(this.firestore, dbConst.kegs);
    const q = query(listRef, where('barId', '==', this.authService.barId), where('id', 'in', kegIds));
    return collectionData(q, { idField: 'id' })
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a as any;
            Object.keys(data).filter(key => Boolean(data[key]?.seconds))
              .forEach(key => {
                if (data[key].seconds) {
                  const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
                  data[key] = date.toDate();
                }
              });
            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate())
            return data;
          });
        })
      ) as Observable<IKeg[]>;
  }

  getKegsForBeverage(beverageId: string) {
    const listRef = collection(this.firestore, dbConst.kegs);
    const q = query(listRef, where('beverageId', '==', beverageId));
    return collectionData(q, { idField: 'id' })
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a as any;
            Object.keys(data).filter(key => Boolean(data[key]?.seconds))
              .forEach(key => {
                if (data[key].seconds) {
                  const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
                  data[key] = date.toDate();
                }
              });
            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate())
            return data;
          });
        })
      ) as Observable<IKeg[]>;
  }

  setStaticItems(kegs: IKeg[]) {
    this.kegs = kegs;
  }
  setStaticItem(keg: IKeg, index: number) {
    this.kegs[index] = keg;
  }

  getStaticItems() {
    return this.kegs;
  }


}
