import { Injectable } from '@angular/core';
import { ITap } from '../../models/tap';

import { dbConst } from './databaseConstants';
import DalHelper from '../helpers/string.helper';
import { KegService } from './keg.service';
import { IKeg } from '../../models/keg';
import { FirestoreCrudService } from './crudService';
import { AuthService } from '../auth.service';
import { doc, Firestore, writeBatch } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class TapService {

  private crudService: FirestoreCrudService<ITap>;

  constructor(
    private firestore: Firestore,
    private kegService: KegService,
    private authService: AuthService) {
    this.crudService = new FirestoreCrudService<ITap>(firestore, authService, dbConst.taps);
  }
  getNewId(): string {
    return this.crudService.getNewId();
  }

  create(item: ITap) {
    return this.crudService.add(item);
  }

  softUpdate(id: string, data: any, uid: string) {
    return this.crudService.softUpdate(id, data, uid);
  }

  update(item: ITap) {
    return this.crudService.update(item);
  }

  async updateWithKeg(item: ITap, keg: IKeg) {
    const user = await this.authService.getUser();
    const batch = writeBatch(this.firestore);


    if (keg && keg.id) {
      const kegPath = DalHelper.getItemPath(dbConst.kegs, keg.id);
      const kegDoc = doc(this.firestore, kegPath);
      keg = {
        ...keg,
        uBy: user?.uid,
        //        plaatoId: keg.plaatoId ? keg.plaatoId : null,
        uDate: new Date(),
      }
      batch.set(kegDoc, keg);
    }

    const tapPath = DalHelper.getItemPath(dbConst.taps, item.id ?? '');
    const tapDoc = doc(this.firestore, tapPath);
    item = {
      ...item,
      uBy: user?.uid ?? '',
      uDate: new Date(),
      kegId: item.kegId ? item.kegId : undefined,
    };

    batch.set(tapDoc, item);

    batch.commit().then(() => {
    }).catch((err) => {
      console.error('error', err);
    });
  }

  delete(item: ITap) {

    const batch = writeBatch(this.firestore);
    const docPath = DalHelper.getItemPath(dbConst.taps, item.id ?? '');
    const tapRef = doc(this.firestore, docPath);
    batch.delete(tapRef);
    if (item.kegId) {
      const updateKeg = {
        uDate: new Date(),
        plaatoApiKey: '',
        plaatoName: '',
        kegtronId: '',
        kegtronName: '',
        customKegMonId: '',
        customKegMonName: ''
      };

      const kegPath = `${dbConst.kegs}/${item.kegId}`;
      const kegDoc = doc(this.firestore, kegPath);
      batch.update(kegDoc, updateKeg);
    }

    return batch.commit();


  }


  removeKegFromTap(id: string) {
    return this.crudService.softUpdate(id, { kegId: '', keg: null });
  }


  getItem(id: string) {
    return this.crudService.get(id);
  }

  getItems(barId: string, limit = undefined) {
    return this.crudService.getItemsOrder(barId, limit);
  }

  getItemsPromise(barId: string) {
    return this.crudService.getItemsOnce(barId, 'name', 100);
  }


}
