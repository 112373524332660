import { Action, ActionReducer } from '@ngrx/store';
import { CLEAR_STATE } from './user/actions';
export function clearStateMetaReducer<State extends {}>(reducer: ActionReducer<State>): ActionReducer<State> {
  return function clearStateFn(state: State | undefined, action: Action) {
    if (action.type === CLEAR_STATE) {
      state = {} as State; // ==> Emptying state here
    }
    return reducer(state, action);
  };
}
