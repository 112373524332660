import { createAction, props } from '@ngrx/store';
import { IUnits } from '../../models/units';

export const loadUnits = createAction('[Units Page] Load Units', props<{ barId: string }>());
export const loadUnitsSuccess = createAction('[Units API] Load Units Success', props<{ units: IUnits }>());
export const loadUnitsFailure = createAction('[Units API] Load Units Failure', props<{ error: any }>());

export const addUnits = createAction('[Settings-Units Page] Add Units', props<{ item: IUnits }>());
export const addUnitsSuccess = createAction('[Units API] Update Units Success', props<{ item: IUnits }>());
export const addUnitsFailed = createAction('[Units API] Update Units Failure', props<{ error: any }>());

export const updateItem = createAction('[Settings-Units Page] Update Units', props<{ item: IUnits }>());
export const updateItemSuccess = createAction('[Units API] Update Units Success', props<{ item: IUnits }>());
export const updateItemFailure = createAction('[Units API] Update Units Failure', props<{ error: any }>());



