import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UnitsService } from '../../services/dal/units.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap, concatMap, tap } from 'rxjs/operators';
import * as fromActions from './actions'
import { ToastService } from 'app/services/toast.service';



@Injectable()
export class UnitsEffects {
  barSettingsService: any;

  constructor(private actions$: Actions, private unitsService: UnitsService, private toastService: ToastService) { }

  getUnits$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromActions.loadUnits),
        mergeMap((action) => this.unitsService.getItem(action.barId)
          .pipe(
            tap((item) => {
              if (!item) {
                this.unitsService.getItemPromise(action.barId)
              }
            }
            ),
            map(units => fromActions.loadUnitsSuccess({ units: units })),
            catchError(error => of(fromActions.loadUnitsFailure({ error })))
          )
        )
      );
  });


  updateUnits$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromActions.updateItem),
        concatMap((action) => of(this.unitsService.update(action.item))
          .pipe(
            map(() => {
              const result = fromActions.updateItemSuccess({ item: action.item });
              this.toastService.saveSuccess();
              return result;
            }),
            catchError(error => of(fromActions.updateItemFailure({ error })))
          )
        )
      );
  });


  handleLoadError$ = createEffect(
    () => {
      return this.actions$
        .pipe(
          ofType(fromActions.loadUnitsFailure),
          tap((action) => {
            this.toastService.error(action.error.message)
          }),
        )
    },
    { dispatch: false }
  );

  handleUpdateError$ = createEffect(
    () => {
      return this.actions$
        .pipe(
          ofType(fromActions.updateItemFailure),
          tap((action) => {
            this.toastService.error(action.error.message)
          }),
        )
    },
    { dispatch: false }
  );

}
