import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ServingSizeService } from 'app/services/dal/serving-size.service';
import { of } from 'rxjs';
import { catchError, mergeMap, filter, map, tap } from 'rxjs/operators';
import * as actions from './actions'



@Injectable()
export class ServingSizeEffects {

  constructor(private actions$: Actions, private servingSizeService: ServingSizeService) { }

  getServingSizes$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(actions.loadServingSizes),
        mergeMap((action) => this.servingSizeService.getItems(action.barId)
          .pipe(
            filter(data => Boolean(data)),
            map(servingSizes => actions.loadServingSizesSuccess({ servingSizes: servingSizes })),
            catchError(error => of(actions.loadServingSizesFailure({ error })))
          )
        )
      );
  });


}
