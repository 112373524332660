<form [formGroup]="form">
  <ion-card>
    <ion-card-header>
      <ion-card-title>Bar</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-item>
        <ion-input
          label="Bar Name"
          labelPlacement="floating"
          type="text"
          placeholder="Bar name"
          formControlName="barName"
          (ionBlur)="saveBar()"
        ></ion-input>
      </ion-item>
      <app-picture-cropper
        label="Bar Avatar"
        labelPosition="none"
        pathFolder="media"
        [inputImageUrl]="bar.photoURL"
        (formSubmitted)="saveBarAvatar(bar, $event)"
        [barId]="bar.id ?? ''"
      ></app-picture-cropper>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-header>
      <ion-card-title>Notifications</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-item lines="full">
        <ion-toggle
          formControlName="allowTapNotifications"
          justify="space-between"
          labelPlacement="floating"
          color="success"
          (ionChange)="saveBar()"
          >Allow Tap Notifications
        </ion-toggle>
      </ion-item>
      <ion-item lines="full">
        <ion-toggle
          formControlName="allowBeverageNotifications"
          justify="space-between"
          labelPlacement="floating"
          color="success"
          (ionChange)="saveBar()"
          >Allow Beverage Notifications
        </ion-toggle>
      </ion-item>
    </ion-card-content>
  </ion-card>
  <ion-card>
    <ion-card-header>
      <ion-card-title>Beverage</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-item lines="full">
        <ion-toggle
          formControlName="displayStatistics"
          justify="space-between"
          labelPlacement="floating"
          color="success"
          (ionChange)="saveBar()"
          >Beverage stats</ion-toggle
        >
      </ion-item>
      <ion-item lines="full">
        <ion-toggle
          formControlName="displayFullscreenToggle"
          justify="space-between"
          labelPlacement="floating"
          color="success"
          (ionChange)="saveBar()"
          >Fullscreen toggle</ion-toggle
        >
      </ion-item>

      <ion-item lines="none">
        <ion-toggle
          formControlName="displayKegPercent"
          justify="space-between"
          color="success"
          (ionChange)="saveBar()"
          >Percent instead of litres</ion-toggle
        >
      </ion-item>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-header>
      <ion-card-title>Api Key</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-list lines="none">
        <ion-item color="warning" *ngIf="newApiKey"
          >Keep it in a safe place!
        </ion-item>
        <ion-item color="light" *ngIf="newApiKey">
          <ion-label>{{ newApiKey }}</ion-label>
        </ion-item>
        <ion-item *ngIf="newApiKey">
          <ion-buttons slot="start">
            <ion-button
              color="success dark"
              (click)="copyApiKeyToClipboard(newApiKey)"
            >
              <ion-icon slot="start" name="copy-outline"></ion-icon>
              Copy to clipboard
            </ion-button>
          </ion-buttons>
        </ion-item>
        <ion-item *ngIf="barSettings">
          <ion-buttons slot="end" *ngIf="!barSettings.apiKey">
            <ion-button (click)="generateApiKey()" *ngIf="!barSettings.apiKey">
              <ion-icon slot="start" name="add"></ion-icon>
              Generate Api Key
            </ion-button>
          </ion-buttons>
          <span *ngIf="barSettings.apiKey && !newApiKey"
            >****************************</span
          >
          <ion-buttons *ngIf="barSettings.apiKey" slot="end">
            <ion-button
              (click)="showApiKey()"
              *ngIf="!newApiKey"
              color="success"
            >
              <ion-icon slot="start" name="eye-outline"></ion-icon>
            </ion-button>
            <ion-button
              (click)="hideApiKey()"
              *ngIf="newApiKey"
              color="success"
            >
              <ion-icon slot="start" name="eye-off-outline"></ion-icon>
            </ion-button>
            <ion-button (click)="confirmDeleteApiKey()" color="danger">
              <ion-icon slot="start" name="trash-outline"></ion-icon>
              Delete Api Key
            </ion-button>
          </ion-buttons>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-header>
      <ion-card-title>Dark mode</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-item lines="full">
        <ion-toggle
          formControlName="darkMode"
          justify="space-between"
          color="success"
          (ionChange)="saveBar()"
          >Use Dark Mode</ion-toggle
        >
      </ion-item>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-header>
      <ion-card-title>Taps washing routines</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-item lines="full">
        <ion-toggle
          formControlName="useWashing"
          justify="space-between"
          color="success"
          (ionChange)="saveBar()"
          >Washing Routines</ion-toggle
        >
      </ion-item>

      <ion-item lines="none">
        <ion-input
          formControlName="washingTapsDays"
          label="Total Days Between Wash"
          labelPlacement="floating"
          type="number"
          placeholder="Days"
          (ionBlur)="saveBar()"
        ></ion-input>
      </ion-item>
      <ion-item lines="none">
        <ion-input
          formControlName="washingUnusedTapsDays"
          label="Days Between Wash for Unused Taps"
          labelPlacement="floating"
          type="number"
          placeholder="Days"
          (ionBlur)="saveBar()"
        ></ion-input>
      </ion-item>
    </ion-card-content>
  </ion-card>
</form>
