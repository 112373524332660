import { Injectable } from '@angular/core';
import { convert, Volume } from 'convert';

@Injectable({
  providedIn: 'root'
})
export class UnitsHelper {

  constructor() { }



  getPlaatoUnitTypes() {
    return [
      {
        id: 'l',
        name: 'Liters',
        type: 'metric'
      },
      {
        id: 'gal',
        name: 'Gallon',
        type: 'imperial'
      }]
  }

  getUnitTypes() {

    return [
      {
        id: 'l',
        name: 'Liters',
        type: 'metric'
      },
      {
        id: 'dl',
        name: 'Deciliters',
        type: 'metric'
      },
      // {
      //   id: 'cl',
      //   name: 'Centiliters',
      //   type: 'metric'
      // },
      {
        id: 'ml',
        name: 'Milliliters',
        type: 'metric'
      },
      {
        id: 'gal',
        name: 'Gallon',
        type: 'imperial'
      },
      {
        id: 'pnt',
        name: 'Pint',
        type: 'imperial'
      },
      // {
      //   id: 'cup',
      //   name: 'Cup',
      //   type: 'imperial'
      // },
      {
        id: 'fl oz',
        name: 'Ounces',
        type: 'imperial'
      }
    ];
  }

  
  statsConvertToUnit(amount: number, decimals: number, fromUnit: string, toUnit: string, displayUnit: boolean) {    
    
    const vol = (Math.round(amount * 100) / 100);

    if(vol < 0.1)
      return 0; 

    try {
      let fromVolume = fromUnit as Volume;
      let toVolume = toUnit as Volume;

      let displayVolume = convert(vol, fromVolume).to(toVolume);
      
      if(displayVolume)
        if(displayUnit)
          return displayVolume.toFixed(decimals) + ' ' + toUnit;
        else
        return displayVolume.toFixed(decimals);
        
        
      return 0;
        
    } catch (error) {
      console.error('error', error);
      
      return 0;      
    }
  }

  toUnitType(amount: number, fromUnit: string, toUnit: string): string {    


    return toUnit;


    // let fromVolume = fromUnit as Volume;
    // let toVolume = toUnit as Volume;


    // const displayVolume = convert(amount, fromVolume).to(toVolume);
    // if(displayVolume == 1)
    //   return displayVolume.singular;
    // return displayVolume.plural;
  }
  
  getUnit(unitType: string) {
    if(unitType === 'gal' || unitType == 'fl oz' ||  unitType == 'fl oz' || unitType == 'pnt' ||  unitType == 'pint') {
      return { system: 'imperial'};
    } else {
      return { system: 'metric'};
    }
  }



  toUnit(amount: number, fromUnit: string, toUnit: string, decimals: number): number {    
    const fromVolume = this.getVolumeType(fromUnit);
    const toVolume = this.getVolumeType(toUnit);

    amount = Number(amount);

    const displayVolume = fromUnit === toUnit ? amount : convert(amount, fromVolume).to(toVolume);
    if(decimals === -1) {
      return displayVolume;
    } else {
      const retVolume = (Math.round(displayVolume * 100) / 100);
      return Number(retVolume.toFixed(decimals));
    }
  }


  private getVolumeType(volumeType: string): Volume {
    
    switch (volumeType) {
      case 'fl oz':
        return 'fl oz';
      case 'pnt':
        return 'pint';
      default:
        return volumeType as Volume;
    }
  }

}
