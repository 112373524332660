import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import * as UserActions from '../state/user/actions';
import * as BeverageActions from '../state/beverage/actions';
import * as BeerStyleActions from '../state/beer-style/actions';
import * as BarSettingsActions from '../state/bar-settings/actions';
import * as BarActions from '../state/bar/actions';
import * as StatisticActions from '../state/statistics/actions';
import * as UnitsActions from '../state/units/actions';
import * as LocationActions from '../state/location/actions';
import * as KegActions from '../state/keg/actions';
import * as TapActions from '../state/tap/actions';
import * as ExternalApiSettingsActions from '../state/external-api-settings/actions';
import * as PlaatoActions from '../state/plaato/actions';
import * as KegtronActions from '../state/kegtron/actions';
import * as CustomKegMonActions from '../state/custom_keg_mon/actions';
import * as SubscriptionActions from '../state/subscription/actions';
import * as FontActions from '../state/font/actions';
import * as OnTapAdvancedActions from '../state/on_tap_advanced/actions';
import * as OnTapTemplateActions from '../state/ontap-template/actions';
import * as NotificationActions from '../state/notification/actions';
import * as StripeProductActions from '../state/stripeProduct/actions';
import { clearAllItems } from '../state/location/actions';
import { environment } from 'environments/environment';
import * as fromStripeCustomer from 'app/state/stripeCustomer';

@Injectable({
  providedIn: 'root'
})
export class DalHelper {

  constructor(
    private store: Store
  ) { }


  dispatchAllDals(barId: string, clearAll: boolean = false) {
    if (clearAll) {
      this.store.dispatch(TapActions.clearAllItems());
      this.store.dispatch(BeverageActions.clearAllItems());
      this.store.dispatch(LocationActions.clearAllItems());
      this.store.dispatch(BeverageActions.clearAllItems());
      this.store.dispatch(BeerStyleActions.clearAllItems());
      this.store.dispatch(KegActions.clearAllItems());
      this.store.dispatch(TapActions.clearAllItems());
      this.store.dispatch(PlaatoActions.clearAllItems());
      this.store.dispatch(KegtronActions.clearAllItems());
      this.store.dispatch(CustomKegMonActions.clearAllItems());
      this.store.dispatch(BarSettingsActions.clearAllItems());
      this.store.dispatch(StatisticActions.clearStatisticsForHomepage());
      this.store.dispatch(FontActions.clearAllItems());
      this.store.dispatch(OnTapAdvancedActions.clearAllItems());
      this.store.dispatch(OnTapTemplateActions.clearAllItems());
      this.store.dispatch(NotificationActions.clearAllItems());
      this.store.dispatch(StripeProductActions.clearAllItems());

    }
    this.store.dispatch(UserActions.loadSubscriptions({ barId }));
    this.store.dispatch(UserActions.loadUser());
    this.store.dispatch(LocationActions.loadItems({ barId }));
    this.store.dispatch(UnitsActions.loadUnits({ barId }));
    this.store.dispatch(BarActions.loadBar({ barId }));
    this.store.dispatch(BeverageActions.loadItems({ barId }));
    this.store.dispatch(BeerStyleActions.loadItems({ barId }));
    this.store.dispatch(KegActions.loadItems({ barId }));
    this.store.dispatch(TapActions.loadItems({ barId }));
    this.store.dispatch(ExternalApiSettingsActions.loadItem({ barId }));
    this.store.dispatch(PlaatoActions.loadItems({ barId }));
    this.store.dispatch(KegtronActions.loadItems({ barId }));
    this.store.dispatch(CustomKegMonActions.loadItems({ barId }));
    this.store.dispatch(BarSettingsActions.loadItem({ barId }));
    this.store.dispatch(BarSettingsActions.loadItems({ barId }));
    this.store.dispatch(StatisticActions.loadStatisticsForHomepage({ barId: barId, limit: 2 }));
    this.store.dispatch(BarSettingsActions.setItem({ id: barId }));

    this.store.dispatch(SubscriptionActions.loadItem({ barId }));

    this.store.dispatch(FontActions.loadItems());
    this.store.dispatch(OnTapAdvancedActions.loadItems({ barId }));
    this.store.dispatch(OnTapTemplateActions.loadItems({ barId }));
    this.store.dispatch(NotificationActions.loadUnreadItems({ barId, limit: environment.notificationsCount }));
    this.store.dispatch(NotificationActions.loadReadItems({ barId, limit: environment.notificationsCount }));


    this.store.dispatch(StripeProductActions.loadItems());


  }
}

