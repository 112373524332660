import { Injectable } from '@angular/core';
import { dbConst } from './databaseConstants';
import { FirestoreCrudService } from './crudService';
import { AuthService } from '../auth.service';
import { ICustomKegMon } from '../../models/customKegMon';
import { Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class CustomKegMonService {
  private crudService: FirestoreCrudService<ICustomKegMon>;
  constructor(
    private firestore: Firestore,
    private authService: AuthService) {
    this.crudService = new FirestoreCrudService<ICustomKegMon>(firestore, authService, dbConst.customKegMons);
  }

  public triggerActive = false;

  async add(item: ICustomKegMon) {
    const newItem = await this.crudService.add(item);
    return newItem;
  }

  async update(item: ICustomKegMon) {
    return await this.crudService.update(item);
  }

  async delete(item: ICustomKegMon) {
    if (item.id)
      await this.crudService.delete(item.id);
  }

  getItem(id: string) {
    return this.crudService.get(id)
  }

  getItems(barId: string) {
    return this.crudService.getItemsOrderBy(barId, "name");
  }

  getItemsOnce(barId: string) {
    return this.crudService.getItemsOnce(barId, "name", 100);
  }

  getItemsPromise(barId: string) {
    return this.crudService.getItemsOnce(barId, "name", 100);
  }



}
