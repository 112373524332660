import { Injectable } from '@angular/core';
import { dbConst } from './databaseConstants';
import { IUser } from '../../models/user';
import { collection, collectionData, Firestore, query, where, limit, doc, docData, updateDoc, setDoc, addDoc, docSnapshots, Timestamp } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import DalHelper from '../helpers/string.helper';
import { FirestoreCrudService } from './crudService';
import { AuthService } from '../auth.service';
import { catchError, filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private firestore: Firestore,
  ) { }


  getUserByEmail(email: string) {
    // Our list method will get all the Entities in the Collection
    const listRef = collection(this.firestore, dbConst.users);
    const q = query(listRef, where('email', '==', email), limit(2));
    return collectionData(q, { idField: 'id' }) as Observable<IUser[]>;
  }

  getItem(userId: string) {
    const itemPath = DalHelper.getItemPath(dbConst.users, userId);

    const docRef = doc(this.firestore, itemPath);
    return docData(docRef, { idField: 'id' })
      .pipe(
        map((value: any) => {
          const data = value as any;
          Object.keys(data).filter(key => Boolean(data[key]?.seconds))
            .forEach(key => {
              if (data[key].seconds) {
                const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
                data[key] = date.toDate();
              }
            });
          Object.keys(data).filter(key => data[key] instanceof Timestamp)
            .forEach(key => data[key] = data[key].toDate())
          return data as IUser;
        }),
        catchError((error: unknown) => {
          console.error(`stream caught ${itemPath}: ${error}`);
          return of(null);
        })
      );
  }


  getMetadataUser(userId: string) {
    const itemPath = DalHelper.getItemPath(dbConst.metadata, userId);
    const docRef = doc(this.firestore, itemPath);
    return docData(docRef, { idField: 'id' })
      .pipe(
        filter((data) => Boolean(data)),
        map((value: any) => {
          const data = value as any;
          Object.keys(data).filter(key => Boolean(data[key]?.seconds))
            .forEach(key => {
              if (data[key].seconds) {
                const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
                data[key] = date.toDate();
              }
            });
          Object.keys(data).filter(key => data[key] instanceof Timestamp)
            .forEach(key => data[key] = data[key].toDate())
          return data;
        }),
        catchError((error: unknown) => {
          console.error(`stream caught : ${error}`);
          return of(null);
        })
      );



  }

  async setMetaDataUser(userId: string) {
    const itemPath = DalHelper.getItemPath(dbConst.metadata, userId);
    const date = new Date();
    const item = doc(this.firestore, itemPath);
    await updateDoc(item, { refreshTime: date });
  }

  async update(item: IUser) {
    const itemPath = DalHelper.getItemPath(dbConst.users, item.uid);

    const userDoc = doc(this.firestore, itemPath);

    if (userDoc !== null) {
      return setDoc(userDoc, item);
    }

    const collRef = collection(this.firestore, dbConst.users);
    return addDoc(collRef, item);
  }


}
