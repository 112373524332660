import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { IExternalApiSettings } from 'app/models/externalApiSettings';
import * as AppState from '../app.state';
import * as Actions from './actions'

export interface State extends AppState.State {
  externalApiSettings: IExternalApiSettings | undefined;
  error: string;
}

const initialState: State = {
  externalApiSettings: undefined,
  error: ''
};

export const stateName = 'external-api-settings';
const getFeatureState = createFeatureSelector<State>(stateName);

//Selectors
export const getItem = createSelector(
  getFeatureState,
  state => state.externalApiSettings
);


export const reducer = createReducer<State>(
  initialState,
  on(Actions.loadItemSuccess, (state, action): State => {
    return {
      ...state,
      externalApiSettings: action.item,
      error: ''
    };
  }),
  on(Actions.loadItemFailure, (state, action): State => {
    return {
      ...state,
      externalApiSettings: undefined,
      error: action.error
    };
  }),
);
