import { Injectable } from '@angular/core';
import { IKeg } from '../../models/keg';
import { dbConst } from './databaseConstants';
import { BeverageService } from './beverage.service';
import { FirestoreCrudService } from './crudService';
import { AuthService } from '../auth.service';
import { collection, collectionData, Firestore, query, Timestamp, where, limit, doc, docData } from '@angular/fire/firestore';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IStripePrice, IStripeProduct } from 'app/models/stripeProduct';

@Injectable({
  providedIn: 'root'
})
export class StripeProductsService {


  constructor(
    private firestore: Firestore) {
  }


  getItems(): Observable<IStripeProduct[]> {

    const listRef = collection(this.firestore, dbConst.stripeProducts);
    const q = query(listRef, where('active', '==', true), limit(10));
    return collectionData(q, { idField: 'id' })
      .pipe(
        map(changes => {

          return changes.map(a => {
            let data = a as any;

            console.log('data', data);


            // //if key is price, then get the prices
            // if (data.id) {
            //   this.getPrices(data.id).subscribe(prices => {
            //     data = {
            //       ...data,
            //       prices: prices
            //     }
            //   });
            // }

            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate());
            return data;
          });













        })
      ) as Observable<IStripeProduct[]>;
  }


  getItem(id: string): Observable<IStripeProduct> {
    const collectionId = `${dbConst.stripeProducts}/${id}`;


    const docRef = doc(this.firestore, collectionId);
    const docDataContent = docData(docRef, { idField: 'id' })
      .pipe(
        map((value: any) => {
          if (!!value) {
            const data = value as any;
            Object.keys(data).filter(key => Boolean(data[key]?.seconds))
              .forEach(key => {
                if (data[key].seconds) {
                  const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
                  data[key] = date.toDate();
                }
              });
            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate())
            return data;
          }
        }),
        catchError((error: unknown) => {
          console.error(`stream caught [${collectionId}] : ${error}`);
          return of(EMPTY);
        })
      );
    return docDataContent;
  }




  getPrices(id: string) {
    const collectionId = `${dbConst.stripeProducts}/${id}/${dbConst.stripePrices}`;

    const listRef = collection(this.firestore, collectionId);
    const q = query(listRef, where('active', '==', true));
    return collectionData(q, { idField: 'id' })
      .pipe(
        map(changes => {
          return changes.map(a => {
            let data = a as any;

            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate());
            data = {
              ...data,
              productId: id
            };
            return data;
          });
        })
      ) as Observable<IStripePrice[]>;
  }
}
