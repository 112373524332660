import { Injectable } from '@angular/core';
import { dbConst } from './databaseConstants';
import { FirestoreCrudService } from './crudService';
import { AuthService } from '../auth.service';
import { IUnits } from '../../models/units';
import { Firestore } from '@angular/fire/firestore';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UnitsService {

  private crudService: FirestoreCrudService<IUnits>;

  units!: IUnits | undefined;

  constructor(
    private db: Firestore,
    private authService: AuthService) {
    this.crudService = new FirestoreCrudService<IUnits>(db, authService, dbConst.units);
  }

  getItem(barId: string) {
    // return of(
    //   {
    //     barId: 'vidar',
    //     type: 'metric',
    //     bottles: 'ml',
    //     cans: 'ml',
    //     kegs: 'l',
    //     pour: 'dl',
    //     servings: 'ml',
    //     plaato: 'l'
    // },
    // );
    return this.crudService.get(barId)
  }

  getItemPromise(barId: string): Promise<IUnits> {
    const promise = new Promise<IUnits>(async (resolve, reject) => {

      if (this.units) {
        resolve(this.units);
      }

      this.units = await this.crudService.getOnce(barId);
      if (!this.units) {
        this.units = {
          barId: barId,
          type: 'metric',
          bottles: 'ml',
          cans: 'ml',
          kegs: 'l',
          pour: 'dl',
          servings: 'ml',
          plaato: 'l'
        };
        this.add(this.units).then(item => {
          this.units = item;
          resolve(this.units);
        });
      } else {
        resolve(this.units);
      }

    });
    return promise;
  }

  add(item: IUnits) {
    return this.crudService.add(item, item.barId);
  }

  update(item: IUnits) {
    if (!!item.id) {
      const updateItem = {
        ...item,
        id: item.barId
      };
      return this.crudService.update(updateItem);
    } else {
      const addItem = {
        ...item
      };
      return this.add(addItem);
    }
  }


}
