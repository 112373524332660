import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, filter } from 'rxjs/operators';
import * as Action from './actions'
import { ToastService } from '../../services/toast.service';
import { ExternalApiSettingsService } from 'app/services/dal/external-api-settings.service';



@Injectable()
export class ExternalApiSettingsEffects {

  constructor(private actions$: Actions, private service: ExternalApiSettingsService, private toastService: ToastService) { }

  getItem$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(Action.loadItem),
        mergeMap((action) => this.service.getItem(action.barId)
          .pipe(
            map(item => Action.loadItemSuccess({ item: item })),
            catchError(error => of(Action.loadItemFailure({ error })))
          )
        )
      );
  });

  handleFetchError$ = createEffect(
    () => {
      return this.actions$
        .pipe(
          ofType(Action.loadItemFailure),
          tap((action) => {
            this.toastService.error(action.error)
          }),
        )
    },
    { dispatch: false }
  );



}
