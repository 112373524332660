import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as Actions from './actions'
import { IStripePrice } from 'app/models/stripeProduct';

export interface State extends EntityState<IStripePrice> {
  selectedId: string;
  error: string;
}

export function sortByName(a: IStripePrice, b: IStripePrice): number {
  return a.description.localeCompare(b.description);
}
export function selectId(a: IStripePrice): string {
  return a.id ?? '';
}
export const adapter: EntityAdapter<IStripePrice> = createEntityAdapter<IStripePrice>({
  sortComparer: sortByName,
  selectId: selectId
});

export const initialState: State = adapter.getInitialState({
  selectedId: '',
  error: ''
});

export const stateName = 'stripePrice';

export const reducer = createReducer<State>(
  initialState,
  on(Actions.clearAllItems, (state) => {
    return adapter.removeAll(state);
  }),
  on(Actions.loadItemsSuccess, (state, { items }) => {
    return adapter.setAll(items, state);
  }),
  on(Actions.loadItemFailure, (state, action) => {
    return {
      ...state,
      entities: {},
      error: action.error
    };
  }),

);


//export const getSelectedPlaatoId = (state: PlaatoState) => state.selectedPlaatoId;

// get the selectors
const {
  // selectIds,
  selectEntities,
  selectAll,
  // selectTotal,
} = adapter.getSelectors();


export const getSelectedId = (state: State) => state.selectedId;
export const getState = createFeatureSelector<State>(stateName);
export const selectAllItems = createSelector(getState, selectAll);
export const selectAllEntities = createSelector(getState, selectEntities);
export const getCurrentId = createSelector(getState, getSelectedId);
export const getCurrentItem = createSelector(selectAllEntities, getCurrentId, (entities, id) => {
  if (!!id) return entities[id];
  return null;
});
export const getEntity = (id: string) => createSelector(selectAllEntities, entities => entities[id]);

