import { createAction, props } from '@ngrx/store';
import { ILocation } from 'app/models/location';

export const clearAllItems = createAction('[DalHelper] Clear All Locations');
export const loadItems = createAction('[Inventory Locations List Page] Load Locations', props<{ barId: string }>());
export const loadItemsSuccess = createAction('[Location API] Load Location Success', props<{ items: ILocation[] }>());
export const loadItemsFailure = createAction('[Location API] Load Location Failure', props<{ error: any }>());


export const updateItem = createAction('[Location Detail Page] Update Keg', props<{ item: ILocation }>());
export const updateItemSuccess = createAction('[Location API] Update Kegs Success', props<{ item: ILocation }>());
export const updateItemFailure = createAction('[Location API] Update Kegs Failure', props<{ error: any }>());

export const addItem = createAction('[Location Detail Page] Add Keg', props<{ id: string, item: ILocation }>());
export const addItemSuccess = createAction('[Location API] Add Kegs Success', props<{ item: ILocation }>());
export const addItemFailure = createAction('[Location API] Add Kegs Failure', props<{ error: any }>());


export const deleteItem = createAction('[Location Detail Page] Delete Keg', props<{ id: string }>());
export const deleteItemSuccess = createAction('[Location API] Delete Kegs Success');
export const deleteItemFailure = createAction('[Location API] Delete Kegs Failure', props<{ error: any }>());


export const setItem = createAction('[Location Detail Page] Set Selected Item', props<{ id: string }>());
export const setItemFromListPage = createAction('[Location List Page] Set Selected Item', props<{ id: string }>());


export const softUpdate = createAction('[Location API] Soft Update Keg', props<{ id: string, data: any, uid: string }>());
export const softUpdateSuccess = createAction('[Location API] Soft Update Keg Success', props<{ id: string, data: any }>());
export const softUpdateFailure = createAction('[Location API] Soft Update Keg Failure', props<{ error: any }>());
