import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StatisticsService } from 'app/services/dal/statistics.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as StatisticsActions from './actions'



@Injectable()
export class StatisticEffects {

  constructor(private actions$: Actions, private statisticsService: StatisticsService) { }

  getStatisticsLimit$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(StatisticsActions.loadStatisticsForHomepage),
        mergeMap((action) => this.statisticsService.getLatestItems(action.barId, action.limit)
          .pipe(
            map(statistics => StatisticsActions.loadStatisticsForHomepageSuccess({ items: statistics })),
            catchError(error => of(StatisticsActions.loadStatisticsFailure({ error })))
          )
        )
      );
  });


  loadStatisticsForTap$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(StatisticsActions.loadStatisticsForTap),
        mergeMap((action) => this.statisticsService.getLatestTapItems(action.tapId, action.limit)
          .pipe(
            map(statistics => StatisticsActions.loadStatisticsForTapSuccess({ items: statistics })),
            catchError(error => of(StatisticsActions.loadStatisticsFailure({ error })))
          )
        )
      );
  });


}
