import { Injectable } from '@angular/core';
import { dbConst } from './databaseConstants';
import { AuthService } from '../auth.service';
import { FirestoreCrudService } from './crudService';
import { IFont } from '../../models/font';
import { Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FontService {
  private crudService: FirestoreCrudService<IFont>;
  constructor(
    private firestore: Firestore,
    private authService: AuthService) {
    this.crudService = new FirestoreCrudService<IFont>(firestore, authService, dbConst.fonts);
  }


  getItem(id: string) {
    return this.crudService.get(id)
  }


  getItems(orderBy: string) {
    return this.crudService.getGeneralItemsOrderBy(orderBy, 100);
  }


  getItemsPromise(barId: string) {
    return this.crudService.getItemsOnce(barId, "name", 1000);
  }
}
