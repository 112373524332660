import { createAction, props } from '@ngrx/store';
import { IKeg } from 'app/models/keg';

export const clearAllItems = createAction('[DalHelper] Clear All Kegs');
export const loadItems = createAction('[Kegs Page] Load Kegs', props<{ barId: string }>());
export const loadItemsFromInitEffect = createAction('[Kegs Effect Initiator] Load Kegs', props<{ barId: string }>());
//export const updateItem = createAction('[Beer Page] Update Keg',props<{ item: IKeg }>());
export const loadItemsFailure = createAction('[Keg API] Load Kegs Failure', props<{ error: any }>());
export const loadItemsSuccess = createAction('[Keg API] Load Kegs Success', props<{ items: IKeg[] }>());

export const updateItem = createAction('[Keg Detail Page] Update Keg', props<{ item: IKeg }>());
export const updateItemSuccess = createAction('[Keg API] Update Kegs Success', props<{ item: IKeg }>());
export const updateItemFailure = createAction('[Keg API] Update Kegs Failure', props<{ error: any }>());

export const addItem = createAction('[Keg Detail Page] Add Keg', props<{ id: string, item: IKeg }>());
export const addItemSuccess = createAction('[Keg API] Add Kegs Success', props<{ item: IKeg }>());
export const addItemFailure = createAction('[Keg API] Add Kegs Failure', props<{ error: any }>());


export const deleteItem = createAction('[Keg Detail Page] Delete Keg', props<{ id: string }>());
export const deleteItemSuccess = createAction('[Keg API] Delete Kegs Success');
export const deleteItemFailure = createAction('[Keg API] Delete Kegs Failure', props<{ error: any }>());


export const setItem = createAction('[Keg Detail Page] Set Selected Item', props<{ id: string }>());
export const setItemFromListPage = createAction('[Keg List Page] Set Selected Item', props<{ id: string }>());


export const softUpdate = createAction('[Keg API] Soft Update Keg', props<{ id: string, data: any, uid: string }>());
export const softUpdateSuccess = createAction('[Keg API] Soft Update Keg Success', props<{ id: string, data: any }>());
export const softUpdateFailure = createAction('[Keg API] Soft Update Keg Failure', props<{ error: any }>());




//export const updateBeverageOnKeg = createAction('[Keg Detail Page] Update Beverage On Keg', props<{ beverage: IBeverage }>());
