import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as fromActions from './actions'
import { IStripeCustomer } from 'app/models/stripeCustomer';


export interface State extends EntityState<IStripeCustomer> {
  selectedId: string | null;
  error: string;
}


export function selectId(a: IStripeCustomer): string {
  return a.id ?? '';
}
export const adapter: EntityAdapter<IStripeCustomer> = createEntityAdapter<IStripeCustomer>({
  selectId: selectId
});

export const initialState: State = adapter.getInitialState({
  selectedId: null,
  error: ''
});

export const stateName = 'stripeCustomer';

// Reducers
export const reducer = createReducer<State>(
  initialState,
  on(fromActions.clearAllItems, (state) => {
    return adapter.removeAll(state);
  }), on(fromActions.loadItemSuccess, (state, { item }) => {
    state = {
      ...state,
      selectedId: item.id ?? null
    }
    return adapter.setOne(item, state);
  }),
  on(fromActions.loadItemFailure, (state, action) => {
    return {
      ...state,
      entities: {},
      error: action.error
    };
  }),



);


// get the selectors
const {
  selectAll,
  selectEntities: selectItemEntities,
  selectIds: selectItemIds,
  selectTotal
} = adapter.getSelectors();


// ***** SELECTORS *******
export const getSelectedId = (state: State) => state.selectedId;
export const getState = createFeatureSelector<State>(stateName);
export const selectIds = createSelector(getState, selectItemIds);
export const selectEntities = createSelector(getState, selectItemEntities);
export const selectAllItems = createSelector(getState, selectAll);
//export const getAllEntities = createSelector(getState, selectEntities);

export const getCount = createSelector(getState, selectTotal);
export const selectCurrentId = createSelector(getState, getSelectedId);
export const selectCurrentItem = createSelector(selectEntities, selectCurrentId, (entities, id) => {
  if (id) return entities[id];
  return null;
});
export const selectEntity = (id: string) => createSelector(selectEntities, entities => entities[id]);
