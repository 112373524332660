<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        *ngIf="!isModal"
        defaultHref="settings"
      ></ion-back-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button *ngIf="isModal" (click)="closeModal()"> Close</ion-button>
    </ion-buttons>
    <ion-title>Bar settings</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content
  class="bar-content"
  *ngrxLet="{ user: user$, bar: bar$, barSettings: barSettings$} as vm"
>
  <app-form
    *ngIf="vm.user && vm.bar && vm.barSettings"
    [user]="vm.user"
    [bar]="vm.bar"
    [barSettings]="vm.barSettings"
    (updateBar)="updateBar($event)"
    (updateBarSettings)="updateBarSettings($event)"
  ></app-form>
</ion-content>
