import { Injectable } from '@angular/core';
import { dbConst } from './databaseConstants';
import { FirestoreCrudService } from './crudService';
import { AuthService } from '../auth.service';
import { IBarSettings } from '../../models/barSettings';
import { Firestore } from '@angular/fire/firestore';
import { map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BarSettingsService {

  private crudService: FirestoreCrudService<IBarSettings>;

  barSettings!: IBarSettings;

  constructor(
    private firestore: Firestore,
    private authService: AuthService) {
    this.crudService = new FirestoreCrudService<IBarSettings>(firestore, authService, dbConst.barSettings);
  }

  getItem(barId: string) {

    const setting$ = this.crudService.get(barId);

    setting$.pipe(
      tap(item => {
        if (!Boolean(item)) {
          const item: IBarSettings = {
            allBeerSortOrder: "name",
            barId: barId,
            favBeerSortOrder: "order",
          };
          this.add(item);
        }

      }),
    );

    return setting$;
  }

  getItems(barId: string) {
    const setting$ = this.crudService.getItems(barId);
    setting$.pipe(
      map(a => {
        const item = a as any;
        return item;
      }),
      tap(items => {
        if (!Boolean(items)) {
          const item: IBarSettings = {
            allBeerSortOrder: "name",
            barId: barId,
            favBeerSortOrder: "order",
          };
          this.add(item);
        }

      })
    );
    return setting$;
  }


  getItemPromise(barId: string): Promise<IBarSettings> {
    const promise = new Promise<IBarSettings>(async (resolve, reject) => {
      if (this.barSettings) {
        resolve(this.barSettings);
      }
      this.barSettings = await this.crudService.getOnce(barId);
      if (this.barSettings === undefined) {
        const item: IBarSettings = {
          allBeerSortOrder: "name",
          barId: barId,
          favBeerSortOrder: "order",
        };
        this.add(item);
      }
      resolve(this.barSettings);
    });
    return promise;
  }

  add(item: IBarSettings) {
    return this.crudService.add(item, item.barId);
  }

  update(item: IBarSettings) {
    return this.crudService.update(item);
  }
  softUpdate(id: string, item: any) {
    return this.crudService.softUpdate(id, item);
  }


}
