import { createAction, props } from '@ngrx/store';
import { ISubscription } from 'app/models/subscription';

export const loadItem = createAction(
  '[App Module Page] Load Subscription',
  props<{ barId: string }>()
);
export const gotoSubscribe = createAction('[Subscription Effect] Goto Subscribe');

export const loadItemSuccess = createAction(
  '[Subscription API] Load Subscription Success',
  props<{ item: ISubscription }>()
);

export const loadItemFailure = createAction(
  '[Subscription API] Load Subscription Failure',
  props<{ error: any }>()
);


