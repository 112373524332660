<ion-item [lines]="labelPosition">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-label>{{ label }}</ion-label>
      </ion-col>
      <ion-col>
        <input
          type="file"
          value=""
          class="fileInput"
          id="file"
          name="file"
          (change)="fileChangeEvent($event)"
          #file
        />

        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [format]="'jpeg'"
          (imageCropped)="imageCropped($event)"
          [onlyScaleDown]="true"
          [resizeToWidth]="200"
          [alignImage]="'center'"
          [imageQuality]="70"
          [output]="'base64'"
        ></image-cropper>
        <ion-button
          color="success"
          expand="full"
          size="medium"
          (click)="save()"
          *ngIf="imageChangedEvent"
        >
          <ion-icon slot="start" name="save"></ion-icon>
          Save image
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

  <label for="file" *ngIf="!imageUrl && !croppedImage">
    <ion-icon
      size="large"
      slot="icon-only"
      color="success"
      name="add-circle-outline"
    >
    </ion-icon>
  </label>
  <ion-thumbnail *ngIf="imageUrl">
    <img [src]="imageUrl" *ngIf="!isUploadingImage" />
  </ion-thumbnail>
  <ion-buttons slot="end">
    <ion-button (click)="confirmDeleteImage()" *ngIf="imageUrl">
      <ion-icon slot="icon-only" color="danger" name="trash-outline"></ion-icon>
    </ion-button>
  </ion-buttons>
  <ion-item *ngIf="isUploadingImage" lines="none">
    <ion-spinner></ion-spinner>
  </ion-item>
</ion-item>
