import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { FileUploadComponent } from '../../../components/file-upload/file-upload.component';

import { ImageCropperModule } from 'ngx-image-cropper';

import { BarPageRoutingModule } from './bar-routing.module';

import { BarPage } from './bar.page';
import { FormComponent } from './form/form.component';
import { LetDirective } from '@ngrx/component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ImageCropperModule,
    LetDirective,
    BarPageRoutingModule,
    FileUploadComponent,
    FormComponent
  ],
  providers: [],
  declarations: [BarPage]
})
export class BarPageModule { }
