import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BarService } from 'app/services/dal/bar.service';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, tap, filter, concatMap } from 'rxjs/operators';
import * as BarActions from './actions'
import { ToastService } from '../../services/toast.service';
import { error } from 'console';
import { IBar } from 'app/models/bar';



@Injectable()
export class BarEffects {

  constructor(
    private actions$: Actions,
    private barService: BarService,
    private toastService: ToastService) { }

  getBar$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(BarActions.loadBar),
        mergeMap((action) => this.barService.getItem(action.barId)
          .pipe(
            filter((data) => Boolean(data)),
            map(bar => BarActions.loadBarSuccess({ bar: bar })),
            catchError(error => {
              return of(BarActions.loadBarFailure({ error }))
            })
          )
        )
      );
  });

  handleFetchError$ = createEffect(
    () => {
      return this.actions$
        .pipe(
          ofType(BarActions.loadBarFailure),
          tap((action) => {
            this.toastService.error(action.error)
          }),
        )
    },
    { dispatch: false }
  );

  updateBar$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(BarActions.updateBar),
        concatMap((action) => of(this.barService.update(action.bar))
          .pipe(
            map(() => {

              const result = BarActions.updateBarSuccess({ bar: action.bar })
              this.toastService.saveSuccess();
              return result;

            }),
            catchError(error => {
              this.toastService.error(error);
              return of(BarActions.updateBarFailure({ error }))
            })
          )
        )
      );
  });


  softUpdate$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(BarActions.softUpdate),
        mergeMap((action) => from(this.barService.softUpdate(action.id, action.data, action.uid))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
              return BarActions.softUpdateSuccess({ id: action.id, data: action.data })
            }),
            catchError((error) => {
              return of(BarActions.softUpdateFailure({ error }))
            })
          )
        )
      );
  });

  softUpdateSuccess$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(BarActions.softUpdateSuccess),
        tap(() => {
          this.toastService.saveSuccess();
        }),
      );
  }, { dispatch: false });


  softUpdateFailure$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(BarActions.softUpdateFailure),
        tap(({ error }) => {
          this.toastService.error(JSON.stringify(error));
        })
      );
  }, { dispatch: false });





}
