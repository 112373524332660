import { Injectable } from '@angular/core';
import { dbConst } from './databaseConstants';
import { FirestoreCrudService } from './crudService';
import { AuthService } from '../auth.service';
import { DocumentData, Firestore, Query, QueryConstraint, QueryFieldFilterConstraint, QueryOrderByConstraint, Timestamp, collection, collectionData, doc, getCountFromServer, getDoc, limit, orderBy, query, startAfter, where } from '@angular/fire/firestore';
import { INotification } from 'app/models/notification';
import { INotificationVM } from '../../models/notification';
import { Data } from '@angular/router';
import { Observable, firstValueFrom, lastValueFrom, map, pipe, take, tap } from 'rxjs';
import { notificationConsts } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private crudService: FirestoreCrudService<INotification>;

  constructor(
    private firestore: Firestore,
    authService: AuthService) {
    this.crudService = new FirestoreCrudService<INotification>(firestore, authService, dbConst.notifications);
  }

  getNewId(): string {
    return this.crudService.getNewId();
  }

  async add(item: INotification, id: string | undefined = undefined) {
    return await this.crudService.add(item, id);
  }

  async update(item: INotification) {
    return await this.crudService.update(item);
  }

  // async getUnreadCount(barId: string): Promise<number> {
  //   const listRef = collection(this.firestore, dbConst.notifications);
  //   const q = query(listRef,
  //     where('barId', '==', barId),
  //     where('read', '==', false));
  //   const snapshot = await getCountFromServer(q);
  //   return snapshot.data().count;
  // }

  async markAllVisibleAsRead(items: INotification[]) {
    const unreadItems = items.filter(x => !x.read);
    unreadItems.forEach(async x => {
      const updateItem = {
        ...x,
        read: true
      }
      await this.crudService.update(updateItem);
    });
  }

  async markAllTapAsRead(barId: string) {
    const listRef = collection(this.firestore, dbConst.notifications);
    const q = query(listRef,
      where('barId', '==', barId),
      where('read', '==', false),
      where('type', '==', notificationConsts.type_tap),
      orderBy('id'), limit(500));
    collectionData(q, { idField: 'id' }).pipe(
      take(1),
      tap((items: any) => {
        console.info('updated items as red', items.length);
        items.forEach(async (item: any) => {
          const updateItem: INotification = {
            ...item,
            read: true
          };
          await this.crudService.update(updateItem);
        });
      })).subscribe();
  }

  async deleteAllReadTapNotifications(barId: string) {
    const listRef = collection(this.firestore, dbConst.notifications);
    const q = query(listRef,
      where('barId', '==', barId),
      where('read', '==', true),
      where('type', '==', notificationConsts.type_tap),
      orderBy('id'), limit(500));
    collectionData(q, { idField: 'id' }).pipe(
      take(1),
      tap((items: any) => {
        console.info('updated items as red', items.length);
        items.forEach(async (item: any) => {
          await this.crudService.delete(item.id);
        });
      })).subscribe();
  }

  async deleteAllTapNotifications(barId: string) {
    const listRef = collection(this.firestore, dbConst.notifications);
    const q = query(listRef,
      where('barId', '==', barId),
      where('type', '==', notificationConsts.type_tap),
      orderBy('id'), limit(500));
    collectionData(q, { idField: 'id' }).pipe(
      take(1),
      tap((items: any) => {
        console.info('updated items as red', items.length);
        items.forEach(async (item: any) => {
          await this.crudService.delete(item.id);
        });
      })).subscribe();
  }

  async softUpdate(id: string, item: any, uid: string) {
    return await this.crudService.softUpdate(id, item, uid);
  }

  async delete(id: string): Promise<void> {
    return await this.crudService.delete(id);
  }


  getItem(id: string) {
    return this.crudService.get(id);
  }

  getItemOnce(id: string) {
    return this.crudService.getOnce(id);
  }

  getItems(barId: string) {
    return this.crudService.getItemsOrderBy(barId, 'uDate', undefined);
  }

  getItemsWithTake(barId: string, take: number) {
    return this.crudService.getItemsOrderBy(barId, 'uDate', 'desc', take);
  }

  async getUnreadItemsWithTakeFrom(barId: string, take: number, startAfterId: string) {

    const listRef = collection(this.firestore, dbConst.notifications);
    const collectionId = `${dbConst.notifications}/${startAfterId}`;
    const startAfterRef = doc(this.firestore, collectionId);
    const startAfterSnap = await getDoc(startAfterRef);

    const q = query(listRef,
      where('barId', '==', barId),
      where('read', '==', false),
      orderBy('uDate', 'desc'),
      limit(take),
      startAfter(startAfterSnap));
    const sub = collectionData(q, { idField: 'id' })
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a as any;
            Object.keys(data).filter(key => Boolean(data[key]?.seconds))
              .forEach(key => {
                if (data[key].seconds) {
                  const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
                  data[key] = date.toDate();
                }
              });
            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate())
            return data;
          });
        })
      ) as Observable<INotification[]>;

    return await firstValueFrom(sub);
  }

  getUnreadItemsWithTake(barId: string, take: number) {

    const wheres: QueryFieldFilterConstraint[] = [
      where('barId', '==', barId),
      where('read', '==', false)
    ];

    const orderBys: QueryOrderByConstraint[] = [
      orderBy('uDate', 'desc'),
    ];

    const limits: QueryConstraint[] = [
      limit(take)
    ]

    return this.crudService.getItemsDynamic(wheres, orderBys, limits);
  }

  async getReadItemsWithTakeFrom(barId: string, take: number, startAfterId: string) {

    const listRef = collection(this.firestore, dbConst.notifications);
    const collectionId = `${dbConst.notifications}/${startAfterId}`;
    const startAfterRef = doc(this.firestore, collectionId);
    const startAfterSnap = await getDoc(startAfterRef);

    const q = query(listRef,
      where('barId', '==', barId),
      where('read', '==', true),
      orderBy('uDate', 'desc'),
      limit(take),
      startAfter(startAfterSnap));
    const sub = collectionData(q, { idField: 'id' })
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a as any;
            Object.keys(data).filter(key => Boolean(data[key]?.seconds))
              .forEach(key => {
                if (data[key].seconds) {
                  const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
                  data[key] = date.toDate();
                }
              });
            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate())
            return data;
          });
        })
      ) as Observable<INotification[]>;

    return await firstValueFrom(sub);
  }

  getReadItemsWithTake(barId: string, take: number) {

    const wheres: QueryFieldFilterConstraint[] = [
      where('barId', '==', barId),
      where('read', '==', true)
    ];

    const orderBys: QueryOrderByConstraint[] = [
      orderBy('uDate', 'desc'),
    ];

    const limits: QueryConstraint[] = [
      limit(take)
    ]

    return this.crudService.getItemsDynamic(wheres, orderBys, limits);

  }


  async getAllItemsWithTakeFrom(barId: string, take: number, startAfterId: string) {

    const listRef = collection(this.firestore, dbConst.notifications);
    const collectionId = `${dbConst.notifications}/${startAfterId}`;
    const startAfterRef = doc(this.firestore, collectionId);
    const startAfterSnap = await getDoc(startAfterRef);

    const q = query(listRef,
      where('barId', '==', barId),
      orderBy('uDate', 'desc'),
      limit(take),
      startAfter(startAfterSnap));
    const sub = collectionData(q, { idField: 'id' })
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a as any;
            Object.keys(data).filter(key => Boolean(data[key]?.seconds))
              .forEach(key => {
                if (data[key].seconds) {
                  const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
                  data[key] = date.toDate();
                }
              });
            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate())
            return data;
          });
        })
      ) as Observable<INotification[]>;

    return await firstValueFrom(sub);
  }

  getItemsOnce(barId: string) {
    return this.crudService.getItemsOnce(barId, 'uDate', 20);
  }

  getItemsPromise(barId: string) {
    return this.crudService.getItemsOnce(barId, 'uDate', 20);
  }


}

