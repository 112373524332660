import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LocationService } from 'app/services/dal/location.service';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as LocationActions from './actions'
import { ToastService } from 'app/services/toast.service';



@Injectable()
export class LocationEffects {

  constructor(
    private actions$: Actions,
    private service: LocationService,
    private toastService: ToastService) { }

  getKegs$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(LocationActions.loadItems),
        mergeMap((action) => this.service.getItems(action.barId)
          .pipe(
            map(items => LocationActions.loadItemsSuccess({ items: items })),
            catchError(error => of(LocationActions.loadItemsFailure({ error })))
          )
        )
      );
  });


  updateKeg$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(LocationActions.updateItem),
        mergeMap((action) => of(this.service.update(action.item))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
              return LocationActions.updateItemSuccess({ item: action.item });
            }),
            catchError(error => of(LocationActions.updateItemFailure({ error })))
          )
        )
      );
  });



  addKeg$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(LocationActions.addItem),
        mergeMap((action) => of(this.service.add(action.item, action.id))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
              return LocationActions.addItemSuccess({ item: action.item });
            }),
            catchError(error => of(LocationActions.addItemFailure({ error })))
          )
        )
      );
  });


  deleteItem$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(LocationActions.deleteItem),
        mergeMap((action) => of(this.service.delete(action.id))
          .pipe(
            map(() => {
              this.toastService.deleteItemSuccess();
              return LocationActions.deleteItemSuccess();
            }),
            catchError(error => of(LocationActions.addItemFailure({ error })))
          )
        )
      );
  });


  softUpdate$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(LocationActions.softUpdate),
        mergeMap((action) => from(this.service.softUpdate(action.id, action.data, action.uid))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
              return LocationActions.softUpdateSuccess({ id: action.id, data: action.data })
            }),
            catchError((error) => {
              return of(LocationActions.softUpdateFailure({ error }))
            })
          )
        )
      );
  });


}
