import { Injectable } from '@angular/core';
import { ISubscription } from '../../models/subscription';
import { dbConst, regionConst } from './databaseConstants';
import { map } from 'rxjs/internal/operators/map';
import { AuthService } from '../auth.service';
import * as firebase from 'firebase/app';
import { Observable, Subject, tap } from 'rxjs';
import DalHelper from '../helpers/string.helper';
import { doc, Firestore, docData } from '@angular/fire/firestore';
import { getFunctions, httpsCallable } from '@angular/fire/functions';
import { getApp } from '@angular/fire/app';
import { FirestoreCrudService } from './crudService';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SubscriptionService {

  public hasPremium?: boolean = undefined;
  public hasPremiumObservable = new Subject<boolean>();
  public isRunning = false;
  public freeItemCount = 5;
  private crudSubscriptionService: FirestoreCrudService<ISubscription>;


  constructor(
    private firestore: Firestore,
    authService: AuthService) {


    this.crudSubscriptionService = new FirestoreCrudService<ISubscription>(firestore, authService, dbConst.subscriptions)

  }

  getSubscription(barId: string) {
    return this.crudSubscriptionService.get(barId);

    // const itemPath = DalHelper.getItemPath(dbConst.subscriptions, barId);
    // const docRef = doc(this.firestore, itemPath);
    // return docData(docRef, { idField: 'id' }) as Observable<ISubscription>;
  }

  async userHasPremium(barId: string, forceLiveCheck: boolean = false): Promise<boolean> {

    const promise = new Promise<boolean>(async (resolve, reject) => {
      if (!forceLiveCheck) {
        resolve(this.hasPremium ?? false);
      } else {

        const functions = getFunctions(getApp(), regionConst.europeWest1);

        const callable = httpsCallable(functions, 'isPremium');
        const data = callable({
          barId
        });
        const dataPromise = data;
        resolve((await dataPromise).data as boolean);
      }
    });
    const promiseValue = await promise;
    if (promiseValue != this.hasPremium) {
      this.hasPremium = promiseValue;
      this.hasPremiumObservable.next(promiseValue);
    }
    return promise;
  }

  createThrivecartPurchaseLink(barId: string, userId: string, email: string) {
    return environment.purchaseLink + `?passthrough[customer_email]=${email}&passthrough[barid]=${barId}&passthrough[userid]=${userId}`;
  }
}
