import { Injectable } from '@angular/core';
import { dbConst } from './databaseConstants';
import { IKegtron } from '../../models/kegtron';
import { AuthService } from '../auth.service';
import { FirestoreCrudService } from './crudService';
import { Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class KegtronService {
  private crudService: FirestoreCrudService<IKegtron>;
  constructor(
    private firestore: Firestore,
    private authService: AuthService) {
    this.crudService = new FirestoreCrudService<IKegtron>(firestore, authService, dbConst.kegtrons);
  }

  async delete(item: IKegtron) {
    if (item.id)
      await this.crudService.delete(item.id);
  }

  getItem(id: string) {
    return this.crudService.get(id)
  }


  getItems(barId: string) {
    return this.crudService.getItems(barId);
  }


  getItemsPromise(barId: string) {
    return this.crudService.getItemsOnce(barId, "name", 100);
  }
}
